<template>
  <table :class="theme" calendar>
    <colgroup>
      <col width="auto"/>
    </colgroup>
    <thead>
    <tr>
      <td v-for="day in weekdays" :key="day">{{ day }}</td>
    </tr>
    </thead>
    <tbody v-if="row">
    <div class="spacer"/>
    <tr v-for="i in row" :key="i">
      <td v-for="d in weekData(i)" :key="d.ymd"
          :class="{ past: today > d.ymd, today: today === d.ymd, selected: selectedYMD === d.ymd, ext: d.ext, out: d.out, emphasis: d.ymd === _emphasis }"
          :disabled="d.out" @click="select(d)">
        <label class="selected-label" v-if="selectedLabel && selectedYMD === d.ymd">{{ selectedLabel }}</label>
        <span v-if="!hideExt || d.ext">{{ d.dto.getDate() }}</span>
      </td>
    </tr>
    </tbody>
    <tbody v-else>
    <tr>
      <td class="no-month" colspan="7">Month required</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Calendar',
  props: {
    month: { type: String, default: moment().format('YYYY-MM') },
    emphasis: String, // 'YYYY-MM-DD'
    selected: {},
    selectedLabel: String,
    rangeStart: {
      type: String,
      default: '',
    },
    rangeEnd: {
      type: String,
      default: '',
    },
    hideExt: { type: Boolean, default: false },
    theme: String,
  },
  data() {
    return {
      arg: null,
      startDay: null,
      lastDate: null,
      row: null,
      ym: null,
    };
  },
  computed: {
    today() {
      return +moment().format('YYYYMMDD');
    },
    _emphasis() {
      return this.emphasis ? +moment(this.emphasis).format('YYYYMMDD') : null;
    },
    selectedYMD() {
      return this.parseDate(this.selected);
    },
    rangeStartYMD() {
      return this.parseDate(this.rangeStart);
    },
    rangeEndYMD() {
      return this.parseDate(this.rangeEnd);
    },
    weekdays() {
      return this.locale === 'ko' ? ['일', '월', '화', '수', '목', '금', '토'] : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    },
  },
  watch: {
    month: 'applyMonth',
  },
  methods: {
    parseDate(dateString) {
      if (!dateString) return null;
      let m;
      if (dateString === 'today') m = moment();
      else {
        const matched = String(dateString).match(/^([+-]?\d+)\s+(day|month|week|year)$/);
        m = matched ? moment().add(+matched[1], matched[2]) : moment(dateString);
      }
      return +m.format('YYYYMMDD');
    },
    getBaseDTO() {
      return new Date(this.arg[1], this.arg[2] - 1, 1);
    },
    getDTO(r, c) {
      const dto = this.getBaseDTO();
      dto.setDate((((r - 1) * 7) + c + 1) - this.startDay);
      return dto;
    },
    dateData(r, c) {
      const dto = this.getDTO(r, c);
      const m = moment(dto);
      const ymd = +m.format('YYYYMMDD');
      const ext = this.month !== m.format('YYYY-MM');
      const out = (this.rangeStart && this.rangeStartYMD > ymd) || (this.rangeEnd && this.rangeEndYMD < ymd);
      return { dto, m, ymd, ext, out };
    },
    weekData(r) {
      return [...Array(7)].map((_, c) => this.dateData(r, c));
    },
    applyMonth() {
      if (this.month) {
        this.arg = String(this.month).match(/^(\d{4})-(\d{2})$/);
        if (!this.arg) throw new Error('invalid month format (YYYY-MM)');
        const dto = this.getBaseDTO();
        this.startDay = dto.getDay();
        dto.setMonth(dto.getMonth() + 1);
        dto.setDate(0);
        this.lastDate = dto.getDate();
        this.row = Math.ceil((this.startDay + this.lastDate) / 7);
      }
    },
    select(d) {
      if (d.out) return;
      if (this.hideExt && d.ext) return;
      this.$emit('select', d.m.format('YYYY-MM-DD'));
    },
  },
  mounted() {
    this.applyMonth();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[calendar] { .w(100%); border-collapse: collapse; border-spacing: 0;
  thead {
    td {.pb(10); .-b(rgba(0, 0, 0, .1)); }
  }
  tbody {
    .spacer { .h(10px); }
    td {.pt(20); .pointer;.rel; .h(42); .-box;
      span {.abs; .w(100%); .tc; .lb(0, 50%); .t-y(50%); z-index: 1;}

      &.selected { .rel;
        span {.c(white);}
        &:before {.cnt; z-index: 0; .abs; .wh(32); .bgc(black); .br(16); .lt(50%, 50%); .t-xyc;}
      }
      .selected-label { .abs; .t(-10); .l; .r; .c(#2C81FF); .fs(12); .z(1); }
      &.emphasis:not(.selected) {
        &:before {.cnt; z-index: 0; .abs; .wh(32); .bgc(rgba(0, 0, 0, 0.2)); .br(16); .lt(50%, 50%); .t-xyc;}
      }
      &.out { .bgc(#EBEBF0); cursor: default;
        span { .o(.4); }
      }
      &.ext { .bgc(#FFF);
        span { .o(.4); }
      }
    }
  }
  td {.tc;}
  &.dark {
    thead {
      td { .c(white); }
    }
    tbody {
      td { .c(white);
        &.selected { .rel;
          span {.c(white);}
          &:before {.cnt; z-index: 0; .abs; .wh(32); .bgc(#C71F1F); .br(16); .lt(50%, 50%); .t-xyc;}
        }
        &.ext { .bgc(transparent);
          span { .o(.4); }
        }
      }
    }
  }
}
</style>
