<template>
  <Modal confirm-modal @close="close" :theme="theme" :class="[options.className]">
    <template v-slot:title v-if="options.title">
      <h2>{{ options.title }}</h2>
    </template>
    <div class="body">
      <div>
        <p v-html="options.body" />
        <template v-if="_get(options, 'showEmphasisText', true)">
          <br />
          <u>{{ emphasisText }}</u>
        </template>
      </div>
      <p class="bts">
        <ColorButton class="btn-cancel" @click="close" type="light-gray" color-only>{{buttonCancel}}</ColorButton>
        <ColorButton class="btn-confirm" @click="confirm" :type="theme === 'dark'?'red':'dark'" color-only>{{ buttonName }}</ColorButton>
      </p>
    </div>
  </Modal>
</template>

<script>
import _get from 'lodash/get';
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'ConfirmModal',
  components: { ColorButton, Modal },
  props: {
    options: Object,
  },
  computed: {
    buttonName() {
      return this.options.buttonName || this.$t('shared.member.confirm.ok');
    },
    buttonCancel() {
      return this.options.buttonCancel || this.$t('shared.member.confirm.cancel');
    },
    /** @type {string} */
    emphasisText() {
      return this.options.emphasisText ?? this.$t('shared.member.confirm.continue');
    },
  },
  data() {
    return {
      theme: 'light',
    };
  },
  methods: {
    _get,
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('resolve');
    },
  },
  mounted() {
    this.theme = this.options.theme || this.theme;
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[confirm-modal] {
  &.dark {.c(white);}
  h2 { .tc; .p(20, 0, 32);}
  .body { .tc; .pb(40);
    .bts { .mt(40);
      > button { .calc-w(50%, 4); .br(30); .fs(16);
        &:nth-of-type(1) { .mr(8); }
      }
    }
  }
}
</style>
