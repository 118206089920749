<template>
  <Modal create-vote-modal @close="close" size="normal">
    <template v-slot:title>
      <h2>{{ $t('_.createVote') }}</h2>
    </template>
    <div class="vote-box" v-if="info">
      <ValidationObserver class="condition" v-if="listModel" ref="validator">
        <ValidationProvider class="vote-type" rules="requiredSelect" name="voteType" v-slot="{errors}">
          <ContentsTitle :title="$t('_.type')" :class="{ hidden: info.model.base.type === 'LVUP_SPECIAL_FEED' }" />
          <DropSelect :class="{error:errors.length, hidden: info.model.base.type === 'LVUP_SPECIAL_FEED'}" :list="typeSelectItem.option.list" v-model="typeSelectItem.kv" use-blank :placeholder="$t('_.notSelect')" />
          <p v-if="on" class="help" v-html="$t('_.voteGuide')" />
          <span v-if="typeSelectItem.kv === 'SEMI_FINAL'"><s :class="{'on':on}" @mouseover="toggleOn" @mouseout="toggleOn" />{{ $t('_.clickButton') }}</span>
          <span v-else-if="typeSelectItem.kv === 'QUARTER_FINAL'"><s :class="{'on':on}" @mouseover="toggleOn" @mouseout="toggleOn" />{{ $t('_.clickButton') }}</span>
        </ValidationProvider>
        <Section class="personal-vote" v-if="voteType === 'DEFAULT'">
          <VoteTextRegister v-model="registerItem.kv" :length="options.length || 10" />
        </Section>
        <Section class="description">
          <ContentsTitle v-model="resultOpenItem.kv" :title="descriptionItem.title" />
          <TextBox :placeholder="$t('_.reward')" max-length="120" v-model="descriptionItem.kv" />
        </Section>
        <Section class="select">
          <ContentsTitle toggle v-model="resultOpenItem.kv" :title="$t('_.openResult')" />
        </Section>
        <Section class="select" v-if="voteType === 'DEFAULT'">
          <ContentsTitle toggle v-model="overlapItem.kv" :title="$t('_.duplicatedVote')" />
        </Section>
        <Section class="voteEnd">
          <ContentsTitle :title="$t('_.endVote')" />
          <DateInput :placeholder="$t('_.endVoteTime')" v-model="dateItem.kv" @open="open" mandatory />
        </Section>
      </ValidationObserver>
      <ColorButton type="dark" class="btn-create" @click="confirm">{{ $t('_.make') }}</ColorButton>
    </div>
  </Modal>
</template>

<script>
import _ from 'lodash';
import Modal from '@shared/components/common/Modal.vue';
import ContentsTitle from '@shared/components/common/ContentsTitle.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import DateInput from '@shared/components/common/input/DateInput.vue';
import DateTimeModal from '@shared/components/common/modal/DateTimeModal.vue';
import { getTime } from '@shared/utils/timeUtils';
import modal from '@shared/mixins/modal';
import TextBox from '@shared/components/common/input/TextBox.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { focusSelector } from '@shared/utils/commonUtils';
import VoteTextRegister from '../VoteTextRegister.vue';

export default {
  name: 'CreateVoteModal',
  lexicon: 'shared.vote.modal',
  components: { ColorButton, TextBox, DateInput, VoteTextRegister, DropSelect, ContentsTitle, Modal },
  props: {
    options: Object,
  },
  mixins: [modal],
  computed: {
    voteType() {
      return this.typeSelectItem.kv;
    },
    voteList() {
      return this.listModel;
    },
    typeSelectItem() {
      return this.listModel.typeSelect;
    },
    registerItem() {
      return this.listModel.directRegister;
    },
    resultOpenItem() {
      return this.listModel.resultOpen;
    },
    overlapItem() {
      return this.listModel.overlap;
    },
    dateItem() {
      return this.listModel.date;
    },
    descriptionItem() {
      return this.listModel.description;
    },
    createTarget() {
      return this.info.model.server.voteCreateTarget;
    },
  },
  data() {
    return {
      isModify: false,
      isNew: false,
      voteInfo: null,
      listModel: null,
      useOther: false,
      info: null,
      on: false,
    };
  },
  methods: {
    async open() {
      const v = await this.$modal(DateTimeModal, {
        useTime: true,
        time: this.dateItem.kv,
        resetHidden: true,
      });
      this.dateItem.kv = v || null;
    },
    toggleOn() {
      this.on = !this.on;
    },
    close() {
      this.$emit('close');
    },
    updateModel() {
      const info = _.cloneDeep(this.info.model.edit);
      this.isModify = this.info.model.server?.subject;
      if (!this.info.model.base.id) info.typeSelect.kv = 'DEFAULT';
      if (this.info.modify) {
        info.typeSelect.kv = this.info.model.server.option.voteOpenType;
        info.directRegister.kv = {
          title: this.info.model.server.subject,
          list: this.info.model.server.answers,
        };
        info.description.kv = this.info.model.server.description;
        info.resultOpen.kv = this.info.model.server.canViewResult;
        info.overlap.kv = this.info.model.server.voteType === 'DUPLICATION';
        info.date.kv = this.info.model.server.voteEndDatetime;
        this.useOther = this.info.model.server.useOther;
      }
      this.listModel = _.cloneDeep(info);
    },

    /** @type{VoteRequestBody} */
    getRequest() {
      return {
        subject: this.typeSelectItem.kv === 'DEFAULT' ? this.registerItem.kv.title : '우승팀을 예측해 주세요.',
        voteType: this.overlapItem.kv ? 'DUPLICATION' : 'SINGLE',
        voteOpenType: this.typeSelectItem.kv,
        canViewResult: this.resultOpenItem.kv,
        description: this.descriptionItem.kv,
        canAddAnswer: false,
        useOther: false,
        answers: this.typeSelectItem.kv === 'DEFAULT' ? this.registerItem.kv.list.map(answer => answer.value) : null,
        voteCreateTarget: this.info.model.base.id ? {
          voteTargetId: this.info.model.base.id,
          voteTargetType: this.info.model.base.type,
        } : null,
        voteStartDatetime: null,
        resultOpenDatetime: null,
        voteEndDatetime: this.dateItem.kv,
      };
    },
    async confirm() {
      await this.$validate(this.$refs.validator, focusSelector('.error input, .error textarea'));

      let res;
      const body = this.getRequest();
      const m = getTime();

      if (this.typeSelectItem.kv === 'DEFAULT' && body.answers.length < 2) {
        this.$toast('_.overTwo', { type: 'fail' });
        return;
      } if (m > body.voteEndDatetime) {
        this.$toast('_.afterNow', { type: 'fail' });
        return;
      } if (this.typeSelectItem.kv === 'DEFAULT' && _.uniqWith(body.answers, _.isEqual).length !== body.answers.length) {
        this.$toast('_.noSame', { type: 'fail' });
        return;
      } if (this.info.modify) {
        /** 투표 수정 */
        res = await this.$services.vote.editVote({ voteId: this.info.model.server.voteId, body });
      } else if (this.info.model.base.type === 'LVUP_SPECIAL_FEED') {
          /** 피드 투표 생성 */
          res = await this.$services.vote.createFeedVote(body);
        } else if (this.info.model.base.type === 'ARENA') {
          /** 아레나 투표 생성 */
          res = await this.$services.vote.createArenaVote({ specialId: this.info.model.base.specialId, body });
        }

      this.$emit('resolve', res);
    },
  },
  mounted() {
    this.info = this.options;
    this.updateModel();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[create-vote-modal] {
  .panel .header { .pt(0); .mb(15);
    h3 { .p(20, 0, 15); .-b(@c-base-gray); .fs(16);}
  }
  .vote-box { .bgc(white); .br(20); .mh-c; .rel;
    .condition {
      .hidden { .hide; }
      [contents-title] { .fs(13);
        h4 { .mb(0);}
      }
      .vote-type {
        [contents-title] { .mb(5);}
        .help { .pt(10); .pl(10); .abs; .lt(15%, 35%); .wh(250, 80); .bgc(#242a32); .c(#ffb600); .tl; .fs(10); .br(12); z-index: 3;}
        > span { .ml(20); .c(#e5bb30); .block; .tl; .fs(10); .mt(5);
          > s { .rel; .mr(5);
            &:before { .cnt; .wh(16); .contain('@{icon}/ico_stress.svg'); .abs; .rt(0, 0);}
            &.on {
              &:before { .contain('@{icon}/ico_stress_on.svg'); }
            }
          }
        }
      }
      .personal-vote {
        [toggle-edit-view] {
          [contents-title] { .-b; .mb(5);}
          > a { .hide; }
          .btn-toggle { .hide;}
          [extra-field] {
            > p {
              .btn-remove { .bgc(@c-base-gray);}
            }
          }
        }
      }
      .description { .-b(@c-base-gray); .pb(10); .mt(10);
        [contents-title] { .mb(5); }
      }
      .select {
        [contents-title] { .mt(10); .-b(@c-base-gray); .pb(10);}
      }
      .winnerVote {
        [contents-title] { .-b(@c-base-gray);}
      }
      .personal-vote {
        [contents-title] { .-b(@c-base-gray);}
        .contentsTitle { .pt(10);}
      }
      .voteEnd {
        [contents-title] { .pt(10); .mb(5);}
        [date-input] { .w(100%);}
      }
    }
    .btn-create { .m(24, 45, 0); .wh(240, 48); .br(50); }
  }
}

</style>
