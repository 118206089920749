<!--이미지 및 영상 선택 컴포넌트-->
<template>
  <div image-input :class="{ sm, feed , 'load-only':loadOnly}">
    <div class="bts">
      <template v-if="!sm && option">
        <button v-for="(item, id) in option.preset" :key="id" @click="selectedSample(item.ori, id)" :class="{ on: activeId === id }" class="sample-btn">
          <img :src="item.thumb" :alt="item.thumb" />
        </button>
      </template>
      <div class="btn-image" v-if="selected.key !== 'load'">
        <ImageSelector :use-zip="useZip" @select="imageSelected" @reset="selected.forSubmit = null" ref="imageSelector" v-if="!viewOnly" />
        <p class="size" v-if="option && option.sizeW && option.sizeH">{{ size }}</p>
      </div>
      <button class="btn-view" :class="{'zip': isZip}" v-if="selected.key === 'load' && isLoaded" @click="openImage">
        <CdnImg :src="selected.forSubmit" />
      </button>
    </div>
    <template v-if="!viewOnly && isLoaded">
      <p class="file" :class="{'hide-name':hideName}">
        <a @click="remove()" class="btn-trash">{{ sm || hideName ? '' : selected.fileName }}</a>
      </p>
    </template>
  </div>
</template>

<script>
import ImageSelector from '@shared/components/common/ImageSelector.vue';
import CdnImg from '@shared/components/common/CdnImg.vue';
import ImageModal from '@shared/components/common/modal/ImageModal.vue';
import { cdnSrc } from '@shared/utils/cdnUtils';

export default {
  name: 'ImageInput',
  components: { CdnImg, ImageSelector },
  props: {
    type: String,
    option: { type: Object, default: null },
    value: { type: Object, default: null },
    sm: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
    loadOnly: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
    feed: { type: Boolean, default: false },
    hideName: Boolean,
    useZip: Boolean,
  },
  data() {
    return {
      model: null,
      selected: {},
      activeId: -1,
    };
  },
  computed: {
    size() {
      return `${this.option.sizeW}px X ${this.option.sizeH}px`;
    },
    isLoaded() {
      return Boolean(this.selected.fileName && this.selected.forSubmit && this.selected.forSubmit.length);
    },
    urlKey() {
      return this.type + 'Url';
    },
    typeKey() {
      return this.type + 'Type';
    },
    isZip() {
      return this.selected.fileName && this.selected.fileName.indexOf('.zip') >= 0;
    },
  },
  watch: {
    value: 'updateModel',
  },
  methods: {

    updateModel() {
      this.model = this.value;
      if (this.model && this.model[this.urlKey] && this.model[this.urlKey].length > 0) {
        const file = this.model[this.urlKey];
        const fileName = file.split('/');
        this.selected = { key: 'load', forSubmit: file, fileName: fileName[fileName.length - 1] };
      } else if (!this.loadOnly) {
        this.selected = { key: 'sample', forSubmit: this.model[this.typeKey] };
        this.activeId = this.selected.forSubmit ? this.selected.forSubmit.slice(-1) - 1 : -1;
      }
    },
    selectedSample(ori, id) {
      this.selected = { key: 'sample', value: ori, forSubmit: `SAMPLE_IMAGE${id + 1}` };
      this.activeId = id;
      this.update();
      this.$nextTick(() => {
        this.$refs.imageSelector.reset();
      });
    },

    async imageSelected({ file, resolve }) {
      const uploadResult = await this.$services.aws.upload(file);
      this.selected = { key: 'load', forSubmit: uploadResult.key, fileName: file.name };
      this.activeId = -1;
      this.update();
      resolve();
    },

    update() {
      this.model = {
        [this.typeKey]: this.selected.key === 'load' ? 'CUSTOM_IMAGE' : this.selected.forSubmit,
        [this.urlKey]: this.selected.key === 'load' ? this.selected.forSubmit : '',
      };
      this.$emit('input', this.model);
    },

    remove() {
      this.$emit('remove');
      this.selected = {};
      this.update();
      this.$nextTick(() => {
        if (this.$refs.imageSelector) this.$refs.imageSelector.reset();
      });
    },
    openImage() {
      if (this.isZip) return;
      this.$modal(ImageModal, { img: cdnSrc(this.selected.forSubmit) });
    },
  },
  mounted() {
    if (this.value) this.updateModel();
  },
  beforeDestroy() {
    this.activeId = -1;
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[image-input] {.ib; .vat;
  .bts {.ib-list; white-space: nowrap;
    .sample-btn {.br(12);}
    > button {.wh(105, 48);.bgc(grey);.bgc(transparent);.vam;overflow: hidden;.rel; overflow: initial;
      &.on:after {.cnt;.abs;.wh(20);.lt(50%, 50%);.t-xyc;.contain('@{icon}/icon-checked.svg');}
      &:nth-of-type(1) {.mr(6);}
      &:nth-of-type(2) {
        &:before {.cnt;.abs;.wh(1, 32);.bgc(rgba(0, 0, 0, 0.1));.rt(-8, 50%);.t-yc; z-index: 1;}
      }
      img {.wh(100%);object-fit: cover; .-a(@c-base-gray); .br(30);}
    }
    .btn-image {.w(80);.mt(4);.ml(15); .rel;
      .size {.fs(9); .abs; .lb(40, -15); .t-xc;}
    }
    .btn-view {.wh(80, 40);object-fit: cover;.br(30);.ml(15); .crop;
      &:after { .cnt; .wh(100%); .abs; .-a(@c-base-gray); .z(2); .br(30); .lt(0, 0); .-box; .o(.5);}
      &.zip { .bgc(#222); .rel;
        &:before { content: 'ZIP'; .fs(16); .bold; .abs; .z(2); .lt(50%, 50%); .t-xyc; .c(white); .o(.5);}
      }
      img { .-a;}
    }
    [image-selector] {
      > label { .wh(80, 40);.pointer; .block; .rel; .bgc(@c-title-black); .br(29); .crop;
        &:hover {.o(.5);}
        &:before { .cnt;.contain('@{icon}/white_camera.svg'); .abs; .wh(20, 16); .lt(30, 12);}
        input { .abs; .l(-10000); }
        & + span {.fs(12); .tr; }
      }
    }
  }
  .file {.tr;.w(100%);.mt(4);.fs(11);
    a {.c(#787781);.ib;.vam;.ml(8); .ellipsis; .max-w(70px);
      &.btn-trash { .rel; .pointer; .pr(30); .h(20);
        &:after { .cnt; .abs; .rt(0, 50%); .t-yc; .wh(20); .bg('@{icon}/ico_trash_dark.svg'); .bg-xc; .no-repeat; .bg-s(20, 20); .bgc(#ebebf0); .ml(6); .br(6);}
      }
    }
  }
  &.feed {
    .bts {.ib;.vam;
      .btn-image {.m(0);
        &:before {content: none;}
      }
      .btn-view {.ml(0);.mr(0);}
    }
    .file {.ib;.vam;width: auto;.mt(0);
      &.hide-name {
        a {
          &.btn-trash { .pr(30);}
        }
      }
    }
  }
  &.sm {.rel;
    .bts {
      .btn-image { .w(64); .m(0);}
      [image-selector] {.w(64);.m(0);.vam;
        &:before {content: none;}
        > label {.wh(64, 24);
          &:before {.lt(50%, 50%); .t-xyc;}
        }
      }
      .btn-view {.wh(64, 24);.ml(0); .mr(0);}
    }
    .file {.ib;.wh(24);.vam;
      a.btn-trash {.ml(0); .wh(24); .ml(0); .pr(0);}
    }
  }
  &.load-only {
    .bts {
      .btn-image { .m(0);}
      .btn-photo {
        &:before {content: none;}
      }
      .btn-view {.ml(0); .mr(0);}
    }
  }
  @media (@tp-up) {
    .bts {
      .btn-image { .w(103);
        .size {.l(50%);}
      }
      .btn-view { .w(103); }
      [image-selector] {
        > label { .wh(103, 40);
          &:before { .wh(20); .lt(41, 11);}
        }
      }
    }
    .file a { .max-w(100px); }
  }
}
.requested [org-img-input].error {
  .br(14); .-a(#f00) !important;
}
</style>
