<template>
  <div image-selector>
    <label>
      <input type="file" :accept="acceptFiles" :key="sq" @change="selected">
      <img class="selected-image" v-if="showImage && src" :src="src">
      <slot v-else :select="select" />
    </label>
    <slot name="selected" v-if="src" :src="src" :reset="reset" />
  </div>
</template>

<script>
import { cdnSrc } from '@shared/utils/cdnUtils';

export default {
  name: 'ImageSelector',
  props: {
    showImage: { type: Boolean, default: true },
    uploadedImage: { type: String, default: null },
    useZip: Boolean,
  },
  data() {
    return {
      sq: 0,
      src: null,
    };
  },
  computed: {
    acceptFiles() {
      let files = 'image/jpeg,image/png';
      if(this.useZip) files += ',.zip';
      return files;
    },
  },
  methods: {
    acceptFileTest(name) {
      return this.useZip ? !/\.(jpg|jpeg|png|zip)$/i.test(name) : !/\.(jpg|jpeg|png)$/i.test(name);
    },
    async selected({ target: { files } }) {
      if (!files || !files[0]) return this.next();
      const file = files[0];
      if (this.acceptFileTest(file.name)) {
        this.$toast('onlyImage', { type: 'fail' });
        return this.next();
      }
      await this.process(file);
      const reader = new FileReader();
      reader.onload = () => this.src = reader.result;
      reader.readAsDataURL(file);
      this.next();
    },
    process(file) {
      return new Promise(resolve => {
        this.$emit('select', { file, resolve });
      });
    },
    select() {
      this.$el.querySelector('label').click();
    },
    reset() {
      this.src = null;
      this.$emit('reset');
    },
    next() {
      this.sq += 1;
    },
  },
  mounted() {
    if (this.uploadedImage) this.src = cdnSrc(this.uploadedImage);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[image-selector] { .rel; .crop;
  label { .block; .f; .rel; .pointer;
    input { .hide; }
    .selected-image { .abs; .wf; object-fit: contain; }
  }
  a { .abs; .rt; }
}
</style>
