<template>
  <div vote-example-item :class="[{'active':active}]" @click="$emit('updateItem',exam)">
      <em>{{exam}}</em>
      <div class="btn-holder">
        <button :class="{view}">
          {{info.answerName}}
          <span v-if="view">{{ per }}</span>
          <s v-if="view" class="handler" :style="{width:(info.fill || 0)+'%'}"/>
        </button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'VoteExampleItem',
  components: {},
  props: {
    exam: { type: Number, default: 0 },
    info: Object,
    view: Boolean,
  },
  data() {
    return {
      model: null,
      voteCount: 0,
      disable: false,
    };
  },
  computed: {
    per() {
      return Number.isNaN(Number(this.info.per)) || this.info.per === undefined ? '0%' : `${this.info.per}%`;
    },
    answer() {
      return this.info.info || this.info.answerName;
    },
    answers() {
      if(this.info.answers) return this.info.answers;
      return this.info.voteInfo.answers;
    },
    active() {
      return this.info ? this.info.isSelected : false;
    },
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[vote-example-item] { .mb(12); .pointer; .rel;
  &.active {
    >em { .bgc(#2c81ff); .c(white); }
    .btn-holder {
      >button { .c(#2c81ff); .rel; .-a(#2c81ff);
        >span { .c(#2c81ff);}
        .handler { .bgc(#2c81ff); .o(.2);}
      }
    }
  }
  >em { .wh(32); .bgc(@c-base-gray); .br(50); .c(@c-placeholder); .fs(14,32px); .tc; .ib; .tr-d(.3s); .abs; .lt(0,4); }
  .btn-holder { .rel ; .pl(40); .wf; .-box; .ib; .br(12); .crop; .vam;
    >button { .rel; .ib; .wh(100%); .p(5); .fs(14); .c(@c-placeholder); .tl; .z(1); .-a(@c-base-gray); .br(12); .tr-d(.3s); .-box; .min-h(40);
      &.view { .pr(50); }
      >span { .abs; .rt(14,7); .c(@c-placeholder); .fs(14); .tr-c(.3s); }
      .handler { .abs; z-index: 0; .bgc(@c-base-gray); .wh(100%); .lt(0,0); .br(12); .o(.2); .tr-d(.3s); }
    }
  }
}
</style>
