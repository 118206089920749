<template>
  <div toggle-edit-view :class="[{on},{'edit':editType},{'list': listType}, {'custom':customType}]">
    <a @click="selected">
      <slot name="header" ref="toggle-edit"></slot>
    </a>
    <ToggleButton class="btn-toggle" v-if="editType && statusView" block :value="toggleOn" />
    <div class="contents-box-holder" :style="{height:`${boxHeight}px`}">
      <div ref="box" class="box">
        <slot name="contents" />
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@shared/components/common/ToggleButton.vue';
import { getUsableCheck } from '@shared/utils/organizerUtils';
import { addResize, removeResize } from '@shared/utils/resizeUtil';

export default {
  name: 'ToggleEditView',
  components: { ToggleButton },
  props: {
    id: { type: Number, default: null },
    show: { type: Boolean, default: false },
    bottom: { type: Number, default: 16 },
    statusView: { type: Boolean, default: true },
    listType: Boolean,
    customType: Boolean,
    fix: Boolean,
    info: null,
  },
  data() {
    return {
      on: true,
      originH: 0,
      init: false,
    };
  },
  computed: {
    editType() {
      return !this.listType && !this.customType;
    },
    toggleOn() {
      return getUsableCheck(this.info);
    },
    boxHeight() {
      return this.on ? this.originH : 0;
    },
  },
  watch: {
    show() {
      if (this.fix) return;
      if (this.show) this.open();
      else this.close();
    },
    info: 'updateSize',
  },
  methods: {
    selected() {
      if (this.fix) return;
      if (this.on) {
        this.close();
        this.$emit('open', -1);
      } else {
        this.open();
        this.$emit('open', this.id);
      }
    },
    updateSize() {
      if (!this.on || !this.$el.querySelector) return;
      this.originH = this.$el?.querySelector('.box').offsetHeight + this.bottom;
    },
    open() {
      if (this.on) return;
      this.on = true;
      this.updateSize();
    },
    close() {
      if (!this.on) return;
      this.on = false;
    },
  },
  mounted() {
    this.updateSize();
    this.close();
    this.init = true;
    if (this.show) this.on = true;
    if (this.fix) this.open();
    addResize(this.$el.querySelector('.box'), this.updateSize);
  },
  beforeDestroy() {
    if (this.$el.querySelector) removeResize(this.$el.querySelector('.box'), this.updateSize);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[toggle-edit-view] { .crop; .rel;
  &.edit { .-b(@c-title-black);
    > a { .h(40); .lh(40); .pointer;}
    .btn-toggle { .abs; .rt(0, 10); pointer-events: none; .z(3);}
    &.on {
      .contents-box-holder {
        .box {overflow: hidden;}
      }
    }
  }
  &.list {
    > a {.wh(100%, 40); .bgc(#16191d); .tl; .c(white); .lh(40); .-box; .pl(20); .rel; .mb(10);
      &:after {.cnt; .abs; .wh(24); .contain('@{icon}/Arrow-Active.svg'); .rt(10, 7); transition: transform .3s; .o(0.4); .t-r(-90deg);}
    }
    &.on {
      a:after {.t-r(90deg); }
    }
    .contents-box-holder { .tl;
      .box {.p(30, 30);}
    }
  }
  > a { .rel; .block;}
  .contents-box-holder { transition: height .3s; .crop;}
}
</style>
