<template>
  <div date-input class="form-control" :class="theme">
    <button @click="$emit('open')" @focus="$emit('focus')">
      <template v-if="model"><span class="date">{{ymd}}</span><span class="time">{{ enHm}}</span></template>
      <template v-else><span>{{ placeholder }}</span></template>
    </button>
  </div>
</template>

<script>
import { getYMD, getHM, getEnHM } from '@shared/utils/timeUtils';

export default {
  name: 'DateInput',
  props: {
    value: Number,
    useTime: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    theme: { type: String, default: '' },
  },
  watch: {
    value: 'updateModel',
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    ymd() {
      return getYMD(this.model);
    },
    hm() {
      return getHM(this.model);
    },
    enHm() {
      return getEnHM(this.model);
    },
  },
  methods: {
    updateModel() {
      this.model = this.value;
    },
  },
  mounted() {
    if(this.value) this.updateModel();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[date-input] { .rel; .ib; .vam; .p(0); .min-w(270); .h(48);
  button{ .ib-list; .wh(100%); .bgc(@c-base-gray); .br(12); .rel; overflow: hidden; .fs(16,48px); .c(@c-placeholder); .tl; .p(0,11); .-box;
    &:after{.cnt; .abs; .wh(100%,1); .bgc(rgba(0,0,0,0.1)); .lb(0,0);}
    >span{.wh(100%,100%); .-box; .rel; .lh(48);
      &.date, &.time { .w(50%); .pl(30); }
      &.date:before, &.time:before{.cnt; .wh(23); .abs; .contain('@{img}/icon/icon-date-active.svg'); .lt(0,50%); .t-yc; }
      &.time:before{.contain('@{img}/icon/icon-time-active.svg'); .o(0.5); }
    }
  }
  &.dark { .min-w(auto);.wh(206,40);
    button { .bgc(rgba(255, 255, 255, 0.04)); .br(6); white-space: nowrap;
      > span {.w(auto); .fs(14,40); .pl(24);
        &:before { .cnt; .contain('@{icon}/icon-time-default.svg'); .wh(18); .mt(0); .abs; .lt(0,50%); .t-yc;}
        &.time{ .pl(5);
          &:before { content:none;}
        }
      }
    }
  }
}
.error [date-input], [date-input].error{
  button:after {.bgc(#f90);}
}
</style>
