let initialized = false;
let touch;
let event;

const init = () => {
  touch = 'ontouchstart' in window;
  event = touch ? { down: 'touchstart', up: 'touchend', move: 'touchmove' }
    : { down: 'mousedown', up: 'mouseup', move: 'mousemove' };
  initialized = true;
};

const enable = (el, drag, start, end) => {
  if (!initialized) init();
  if (el._dragHelper) return;
  let downX;
  let downY;
  let touchId;

  const move = e => {
    e.preventDefault();
    e.stopImmediatePropagation();
    const point = touch ? Array.prototype.find.call(e.touches, t => t.identifier === touchId) : e;
    if (!point) return;
    const { clientX, clientY } = point;
    drag({ downX, downY, clientX, clientY, deltaX: clientX - downX, deltaY: clientY - downY });
  };

  const up = () => {
    if (end) end();
    touchId = null;
    window.removeEventListener(event.move, move);
    window.removeEventListener(event.up, up);
  };

  el._dragHelper = e => {
    e.preventDefault();
    e.stopImmediatePropagation();
    let point;
    if (touch) {
      [point] = e.targetTouches;
      touchId = point.identifier;
    } else {
      point = e;
    }
    downX = point.clientX;
    downY = point.clientY;
    drag({ downX, downY, clientX: downX, clientY: downY, deltaX: 0, deltaY: 0, start: true });
    window.addEventListener(event.move, move, { passive: false });
    window.addEventListener(event.up, up);
  };

  el.addEventListener(event.down, el._dragHelper, { passive: false });
};

const destroy = el => {
  if (el._dragHelper) el.removeEventListener(event.down, el._dragHelper);
};

export const setDrag = (el, down, move, allowTouch = false) => {
  const touchMove = e => {
    if (!allowTouch) e.preventDefault();
    move(e.touches[0]);
  };

  const setMouse = () => {
    const onUp = () => {
      window.removeEventListener('mouseup', onUp);
      window.removeEventListener('mousemove', move);
    };
    el.addEventListener('mousedown', e => {
      down(e);
      window.addEventListener('mouseup', onUp);
      window.addEventListener('mousemove', move);
    });
  };

  const setTouch = () => {
    const touchEnd = () => {
      window.removeEventListener('touchend', touchEnd);
      window.removeEventListener('touchcancel', touchEnd);
      window.removeEventListener('touchmove', touchMove);
    };
    el.addEventListener('touchstart', e => {
      if (!allowTouch) e.preventDefault();
      down(e.touches[0]);
      window.addEventListener('touchend', touchEnd);
      window.addEventListener('touchcancel', touchEnd);
      window.addEventListener('touchmove', touchMove, { passive: false });
    }, { passive: false });
  };

  if ('ontouchstart' in window) {
    setTouch();
  } else {
    setMouse();
  }
};

export default { enable, destroy };
