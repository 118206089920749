<!--토글 버튼 컴포넌트  -->
<template>
	<button toggle-button @click.stop :class="{'on': per > 50, block}">
    <span ref="cover">
      <span ref="handle"/>
    </span>
		<i class="active" :style="{'opacity':`${per/100}`}"/>
	</button>
</template>

<script>
export default {
	name: 'ToggleButton',
	props: {
		value: {type: Boolean, default: false},
		block: {type: Boolean, default: false},
	},
	data() {
		return {
			per: 0,
			goalX: 0,
			downX: 0,
			startX: 0,
			limitX: 0,
			updateX: 0,
			moveX: 0,
			moveP: 0,
			animationId: 0,
			parent: null,
			handle: null,
			cover: null,
			isDown: false,
			model: false,
		};
	},
	watch: {
		value() {
			if (this.value !== this.model) {
				this.moveX = 0;
				this.startMove(true);
			}
		},
    block(val) {
      if (val) {
        this.parent.removeEventListener('mousedown', this.ready);
      }
      else {
        this.parent.addEventListener('mousedown', this.ready);
      }
    }
	},
	methods: {
		ready(e) {
			e.stopPropagation();
			if (e.target === this.parent) return;

			if (this.isDown) this.clear();
			this.isDown = true;
			this.parent.addEventListener('mousemove', this.move);
			document.body.addEventListener('mouseup', this.clear);

			this.downX = e.pageX;
			this.startX = this.handle.offsetLeft;
		},
		move(e) {
			this.updateX = e.pageX;
			this.handleMove();
		},
		clear(e) {
			const isClick = this.moveP <= 3;
			this.moveP = 0;
			this.moveX = 0;
			this.isDown = false;
			this.parent.removeEventListener('mousemove', this.move);
			document.body.removeEventListener('mouseup', this.clear);

			if (e) this.startMove(isClick);
		},
		handleMove() {
			let v = this.startX - (this.downX - this.updateX);

			if (v < 0) v = 0;
			else if (v > this.limitX) v = this.limitX;

			this.handle.style.left = `${v}px`;
			this.moveP += 1;
			this.updatePer(v);

		},
		startMove(e) {
			if (this.moveX !== 0) return;

			const cv = this.handle.offsetLeft + (this.handle.offsetWidth / 2);
			const ev = this.cover.clientWidth;

			if (e) this.goalX = cv > ev / 2 ? 0 : this.limitX;
			else this.goalX = cv > ev / 2 ? this.limitX : 0;

			this.goalX = this.goalX - this.handle.offsetLeft;
			this.startX = this.handle.offsetLeft;

			this.animationId = requestAnimationFrame(this.loop);
		},
		loop() {
			this.moveX += this.goalX * .15;
			let v = this.startX + this.moveX;
			let finish = false;
			if (v <= 0) {
				v = 0;
				finish = true;
				this.model = false;
			} else if (v >= this.limitX) {
				v = this.limitX;
				finish = true;
				this.model = true;
			}

			this.handle.style.left = `${v}px`;
			this.updatePer(v);

			if (finish) {
				this.$emit('input', this.model);
				cancelAnimationFrame(this.animationId);
			} else {
				this.animationId = requestAnimationFrame(this.loop);
			}
		},
		updatePer(v) {
			this.per = 100 * (v / this.limitX);
		},
		updateModel() {
			this.model = this.value;
		},
	},
	beforeDestroy() {
		this.parent.removeEventListener('mousedown', this.ready);
		this.clear();
	},
	mounted() {
		if (this.value) this.updateModel();
		this.parent = this.$el.parentNode;
		this.handle = this.$refs.handle;
		this.cover = this.$refs.cover;
		this.limitX = this.cover.clientWidth - this.handle.offsetWidth;
		if (!this.block) this.parent.addEventListener('mousedown', this.ready);

		const l = this.model ? this.limitX : 0;
		this.handle.style.left = `${l}px`;
		this.updatePer(l);

	}
}
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[toggle-button] { .wh(38, 20); .ib; .vat; .rel; .contain('@{shared}/bg/btn-toggle-bg.svg'); .bgc(transparent);
	&.on {
		> span > span {.bgc(white);}
	}
	> span { width: calc(100% - 6px); .h(100%); .mh-c; .block; .rel;
		> span { .wh(14, 14); .br(7); .bgc(#D8D8D8); .abs; .block; z-index: 1; .lt(0, 0); .mt(3); transition: background-color .5s;}
	}
	> i {.block; .abs; .lt(0, 0); .wh(37, 20); .contain('@{shared}/bg/btn-toggle-active.svg'); opacity: 0;}
}
</style>
