<template>
  <span text-input :class="[{'focus':focusOn, disabled, error , center},{sm},theme]">
    <input :class="{ password: type === 'password' }" ref="input" :type="type === 'password' ? '' : type"
      @input="input" :placeholder="placeholder" :readonly="readonly" :disabled="disabled" v-model="t0"
      @keyup.enter="enter" @blur="blur" @focus="focus" @change="change" :tabindex="tabindex" />
    <a @click="reset" v-if="useReset">
      <svg-reset-gray />
    </a>
  </span>
</template>
<script>
import SvgResetGray from '@shared/graphics/svg-reset-gray.vue';

export default {
  name: 'TextInput',
  components: { SvgResetGray },
  props: {
    value: { default: '' },
    placeholder: { type: String, default: '' },
    number: { type: Boolean, default: false },
    digit: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    sm: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    maxLength: { type: Number, default: 999 },
    /** @type string{'white' | 'black' | 'dark'} */
    theme: { type: String, default: 'white' },
    useReset: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    tabindex: { type: String, default: '0' },
  },
  data() {
    return {
      t0: '',
      focusOn: false,
    };
  },
  watch: {
    value: 'update',
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.t0 = this.value;
    },
    input(e) {
      if (this.disabled) return;
      const { value } = e.target;
      this.t0 = (() => {
        switch (true) {
          case this.digit:
            return value.replace(/[^\d]/gi, '');
          case this.number:
            return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          default:
            return value;
        }
      })();
      if (this.maxLength > 0) this.t0 = this.t0.slice(0, this.maxLength);
      this.$emit('input', this.t0);
    },
    change() {
      this.$emit('change', this.t0);
      this.update();
    },
    blur() {
      this.focusOn = false;
      if (this.number) this.$emit('blur', parseInt(this.t0, 10));
      else this.$emit('blur', this.t0);
    },
    focus() {
      this.focusOn = true;
      if (this.number) this.$emit('focus', parseInt(this.t0, 10));
      else this.$emit('focus', this.t0);
    },
    reset() {
      this.t0 = '';
      this.$emit('input', this.t0);
    },
    enter() {
      if(this.error) return;
      this.$emit('enter');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[text-input] { .block; .-box; .c(@c-placeholder); .h(48); .br(12); .medium; .crop; .rel; .lh(0);
  &:after {.cnt; .abs; .wh(100%, 1); .bgc(rgba(0, 0, 0, 0.1)); .t-s(0); .lb(0, 0); transition: transform .3s;}
  &.focus:after {.bgc(rgba(0, 0, 0, 0.9)); .t-s(1);}
  .password { -webkit-text-security: disc; }
  input, input[type='text'], input[type='password'], input[type='number'] {.fs(16, 48px); .wh(100%); .-a; .bgc(@c-base-gray); .p(0, 11); .-box; .c(@c-title-black); .br(0);
    &::placeholder {.c(@c-placeholder); .medium;}
    &:disabled{ .not-allowed; }
  }
  > * {.ib;}
  em {.fs(16, 48px); .h(48); .pl(12);}
  .msg {.fs(9); .c(@c-placeholder); .tr; .hide; .mt(2); .block;}

  &.sm { .wh(80, 30); .br(8);
    input {.fs(12, 30px); .tc;}
  }
  &.error {
    &:after { .bgc(#f90); .t-s(1); }
  }
  &.black, &.dark { .h(40); .c(#fff); .br(6);
    &:after {.cnt; .abs; .wh(100%, 1); .t-s(0); .lb(0, 0); transition: transform .3s;}
    &.focus:after {.bgc(#fff); .t-s(1);}
    input { .bgc(rgba(255, 255, 255, 0.04)); .-b(rgba(255, 255, 255, 0.1)); .c(#fff); .br(0); .fs(14);}
    &.error {
      &:after {.bgc(#e5bb30); .t-s(1);}
    }
  }
  &.center {
    input { .tc; }
  }
  &.sm { .wh(80, 30); .br(8);
    input {.fs(12, 30px);.tc;}
  }
  svg { .abs; .rt(6, 6); }
}
</style>
