export const VOTE = {
  base: { voteId: '', arenaId: '', specialId: '', owner: '', time: '' },
  server: null,
  edit: {
    typeSelect: {
      name: '투표타입',
      type: 'DropSelect',
      option: {
        list: [
          { label: '4강 선정시 투표 자동 생성', key: 'semifinals', value: 'SEMI_FINAL' },
          { label: '8강 선정시 투표 자동 생성', key: 'quarterfinals', value: 'QUARTER_FINAL' },
          { label: '직접 입력', key: 'default', value: 'DEFAULT' },
        ],
      },
      mandatory: true,
      kv: '',
    },
    directRegister: {
      key: 'directRegister',
      type: 'DirectRegister',
      kv: {
        title: '',
        list: [{}],
      },
    },
    description: {
      title: '투표 안내',
      kv: '',
    },
    resultOpen: {
      title: '투표 진행중 결과공개',
      type: 'GuidTitle',
      kv: false,
    },
    overlap: {
      title: '중복 투표',
      type: 'GuidTitle',
      kv: false,
    },
    date: {
      name: '투표마감',
      type: 'DateInput',
      kv: null,
    },
  },
  update: true,
};
