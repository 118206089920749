<template>
  <div pre-vote>
    <div v-if="info.base.owner" class="org-box">
      <div class="content">
        <h3 v-html="$t('_.whoWinner')" />
        <button class="btn-create" @click="openCreateModal">{{ $t('_.createVote') }}</button>
        <img class="woman" src="/shared/img/woman.svg" />
      </div>
    </div>
    <div v-else class="user-box">
      <div class="content">
        <img class="man" src="/shared/img/man.svg" />
        <h3 v-html="$t('_.aboutArenaVote')" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateVoteModal from '@shared/components/vote/modal/CreateVoteModal.vue';

export default {
  name: 'PreVote',
  lexicon: 'shared.vote',
  props: {
    info: Object,
  },
  computed: {
    step() {
      return this.info.base.step;
    },
  },
  watch: {
    step() {
    },
  },
  methods: {
    async openCreateModal() {
      this.info.server = await this.$modal(CreateVoteModal, { model: this.info });
    },
  },
  mounted() {
  },
};

</script>

<style lang="less">
@import '~@shared/less/proj.less';

[pre-vote] { .h(252);
  .org-box {.wh(100%); .bgc(white); .br(20);
    .content { .h(100%); .flex-middle; .rel;
      h3 { .c(black); .fs(16, 22px); .tc; .block; .mb(20);}
      img { .w(150); .mr(30); .block; .mb(6); .abs; .lb(50%, -6); .t-xc; .hide;}
      .btn-create { .wh(200, 48); .block; .mh-c; .bgc(@c-title-black); .o(.8); .br(30);.fs(16); .c(white);}
    }
  }
  .user-box { .wh(100%); .fs(16); .c(black); .tc; .rel; .bgc(white); .br(20); .flex-center;
    .content {
      img { .wh(90); .br(200);}
      h3 { .pt(10); .fs(16); .c(black); .tc;}
    }
  }
  @media (@tp-up) {
    .h(272);
    .org-box {
      .content {
      }
    }
    .user-box {
      .content {
        .man { .wh(100); .br(200); }
        h3 { .fs(18, 24px); }
      }
    }
  }
  @media (@ds-up) {
    .h(478);
    .org-box {
      .content { .pb(140);
        h3 { .fs(20, 30px);}
        img { .w(250); .block;}
        .btn-create { .h(56); .fs(16, 56px);}
      }
    }
    .user-box {
      .content {
        .man { .wh(120); }
        h3 { .fs(20, 32px); }
      }
    }
  }
}

</style>
