'
<template>
  <div contents-title :class="{guide}">
    <h4>{{ title }}<em v-if="mandatory">[필수]</em><s class="icon" :class="{on:isOn}" @click="guideOn" />
      <ToggleButton v-if="toggle" @input="change" v-model="model" />
    </h4>
  </div>
</template>

<script>
import ToggleButton from '@shared/components/common/ToggleButton.vue';

export default {
  name: 'ContentsTitle',
  components: { ToggleButton },
  props: {
    type: { type: String, default: null },
    value: { type: Boolean, default: false },
    title: { type: String, default: '' },
    guide: { type: Boolean, default: false },
    toggle: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
  },
  data() {
    return {
      isOn: false,
      frameId: null,
      iconItem: null,
      toggleStatus: true,
      resolve: null,
      model: false,
    };
  },
  watch: {
    value: 'update',
  },
  methods: {
    async guideOn(e) {
      e.stopPropagation();

      if (this.isOn) return;
      await new Promise(resolve => {
        this.isOn = true;
        this.$emit('guideOn', { resolve, type: this.type, target: this.$el.querySelector('.icon') });
      });
      this.$emit('guideOn', null);
      this.isOn = false;
    },
    update() {
      this.model = this.value;
    },
    change(v) {
      this.model = v;
      this.$emit('input', this.model);
    },
  },
  mounted() {
    if (this.value) this.update();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[contents-title] { .rel;
  &.mandatory {
    h4 {.ib;}
  }
  h4 { .rel; .medium; .c(@c-title-black); .mb(6);
    .icon {.hide;}
    [toggle-button] {.abs; .rt(0, 0); .z(1);}
    em {.c(@c-base-red); .fs(11); .ib; .vat; .mt(1); .ml(4);}
  }
  &.guide {
    h4 {
      .icon {.ib; .wh(16); .contain('@{icon}/icon-help-default.svg'); .ml(2); .vam; .pointer; .mb(2);
        &.on {.contain('@{icon}/icon-help-active.svg');}
      }
    }
  }
}
</style>
