<template>
  <div vote v-if="server">
    <PreVote :info="voteInfo" v-if="statusReady" />
    <template v-else>
      <VoteProgress :info="voteInfo" v-if="server.answers && server.answers.length" />
      <VoteGuide :info="voteInfo" v-else-if="!isFeed && statusGuide" />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { VOTE } from '@shared/data/constants/vote';
import PreVote from '@shared/components/vote/PreVote.vue';
import Specific from '@shared/types/Specific';
import VoteGuide from '@shared/components/vote/VoteGuide.vue';
import VoteProgress from '@shared/components/vote/VoteProgress.vue';

export default {
  name: 'Vote',
  components: {
    VoteProgress,
    VoteGuide,
    PreVote,
  },
  props: {
    info: Specific,
  },
  data() {
    return {
      voteInfo: null,
    };
  },
  computed: {
    isFeed() {
      return this.voteInfo.base.type === 'LVUP_SPECIAL_FEED';
    },
    updateValue() {
      return this.voteInfo?.update;
    },
    server() {
      return this.voteInfo?.server;
    },
    statusReady() {
      return !this.isFeed && this.voteInfo.base.step === 0;
    },
    statusGuide() {
      return this.voteInfo.base.step === 1 && this.server.answers.length === 0 && (this.server.option.voteOpenType === 'SEMI_FINAL' || this.server.option.voteOpenType === 'QUARTER_FINAL');
    },
  },
  watch: {
    server() {
      this.updateStep();
    },
    updateValue() {
      this.updateInfo();
    },
  },
  methods: {
    async updateInfo() {
      const targetId = this.voteInfo.base.id;
      const targetType = this.voteInfo.base.type;
      const vote = await this.$services.vote.getVoteInfo({ targetId, targetType });
      this.voteInfo.server = vote || {};
      this.updateStep();
    },
    init() {
      if (!this.info.id) return;
      this.voteInfo = _.cloneDeep(VOTE);
      this.voteInfo.base = this.info;
      this.updateInfo();
    },
    updateStep() {
      if (!this.voteInfo.server || !this.voteInfo.server.option) {
        this.voteInfo.base.step = 0;
        return;
      }
      this.voteInfo.base.step = 1;
      this.voteInfo.base = _.cloneDeep(this.voteInfo.base);
    },
  },
  async mounted() {
    this.init();
  },

};

</script>

<style lang="less">
@import '~@shared/less/proj.less';
[vote] {
  h1, h2, h3, h4, h5, h6 {font-family: 'Noto Sans', 'Malgun Gothic', '맑은 고딕', sans-serif !important;}
}
</style>
