<template>
  <article vote-modify>
    <div class="holder">
      <p v-if="on" class="help">{{ $t('_.noEdit') }}</p>
      <button class="btn-modify" :disabled="disabled" @click="openInputModal">{{ $t('_.editVote') }}<s :class="['icon', on ? 'on': '']" @mouseenter.stop="isOn" @mouseleave.stop="isOn" /></button>
      <button class="btn-complete" @click="closeVote">{{ $t('_.forceEnd') }}</button>
    </div>
    <button class="btn-close" @click="close">
      <svg-x />
    </button>
  </article>
</template>

<script>
import SvgX from '@shared/graphics/svg-x.vue';
import CreateVoteModal from '@shared/components/vote/modal/CreateVoteModal.vue';

export default {
  name: 'VoteModify',
  lexicon: 'shared.vote',
  components: { SvgX },
  props: {
    info: { type: Object, default: null },
    participant: { type: Number, default: 0 },

  },
  data() {
    return {
      model: null,
      on: false,
    };
  },
  computed: {
    disabled() {
      return this.info.server.totalVotingCount > 0 || (this.info.server.option.voteOpenType !== 'DEFAULT' && this.info.server.answers.length > 0);
    },
    isFeed() {
      return this.info.base && (this.info.base.type === 'LVUP_SPECIAL_FEED' || this.info.base.type === 'LEAGUE_FEED');
    },
  },
  watch: {
    info: 'updateModel',
  },
  methods: {
    isOn() {
      this.on = !this.on;
    },
    async openInputModal() {
      const v = await this.$modal(CreateVoteModal, { model: this.info, modify: true, length: this.isFeed ? 16 : 10 });
      this.info.server = v;
      this.close();
    },
    close() {
      this.$emit('close');
    },
    async closeVote() {
      this.info.server = await this.$services.vote.closeVote(this.info.server.voteId);
      this.$emit('close');
    },
    updateModel() {
    },
    update() {
    },
  },
  mounted() {
  },
};

</script>

<style lang="less">
@import '~@shared/less/proj.less';

[vote-modify] { .abs; .br(15); .wh(100%); .z(12); .tc; .bgc(rgba(0, 0, 0, .3));
  .holder { .abs; .z(3); .lt(50%, 50%); .t-xyc; .w(100%);
    .help { .bgc(#242a32); .tc; .h(40); .br(12); .c(#ffb600); .fs(11, 40px); .abs; .lt(50%, -50); .t-xc; .w(220); .-box;}
    button { .wh(140, 56); .br(40); .fs(16); .rel;
      &.btn-modify { .bgc(white); .c(black); .mr(12);
        &:disabled { .bgc(@c-text-gray);}
      }
      &.btn-complete { .ib; .bgc(black); .c(white);}
    }
    .icon { .rel; .ib; .wh(24); .contain('@{shared}/icon/ico_tooltip_white.svg'); .vam; .mb(3); .ml(4); }
  }
  .btn-close {.wh(24); .abs; .rt(16, 16); .z(100); .stroke(white);}

  @media (@tp-up) {
    .holder { width: auto;
      button { .wh(240, 56);
        &.btn-modify { .block; .mb(20); .mr(0);}
        &.btn-complete { .block;}
      }
      .help { .bgc(#242a32); .wh(220, 40); .br(12); .c(#ffb600); }
      .icon {
        &:before { .abs; .lt(10, 5);}
      }
    }
  }
}

</style>
