<template>
  <Modal image-modal @close="$emit('close')">
    <div class="image-holder">
      <img :src="options.img" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';

export default {
  name: 'ImageModal',
  components: { Modal },
  props: {
    options: Object,
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[image-modal] { .z(1030);
  .panel.large { .max-w(90%); .w; }
  .scroll-area { .p !important; overflow: auto !important; .m(10,20,20,20);
    &::-webkit-scrollbar-thumb { .bgc(#666); .br(0); }
    &::-webkit-scrollbar-corner { .bgc(#aaa); }
    &::-webkit-scrollbar-track { .bgc(#aaa); }
  }
  .image-holder { .rel;
    img { object-fit: contain; object-position: center; }
  }
}

</style>
