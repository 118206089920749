<template>
  <ToggleEditView vote-text-register fix v-if="model" :status-view="false">
    <div slot="contents">
      <ContentsTitle class="contentsTitle" :title="$t('_.voteContent')" />
      <ValidationProvider rules="required" name="voteTitle" v-slot="{errors}">
        <TextInput v-model="model.title" :placeholder="$t('_.inputQuestion')" :class="{error:errors.length}" />
      </ValidationProvider>
      <ContentsTitle class="contentsTitle" :title="$t('_.item')" />
      <ExtraField v-model="model.list" :placeholder="$t('_.item')" :max="length" rules="required" validation-name="voteItem" />
    </div>
  </ToggleEditView>
</template>

<script>
import _ from 'lodash';
import ContentsTitle from '@shared/components/common/ContentsTitle.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import ExtraField from '@shared/components/common/input/ExtraField.vue';
import ToggleEditView from '@shared/components/common/ToggleEditView.vue';

export default {
  name: 'VoteTextRegister',
  lexicon: 'shared.vote',
  components: { ToggleEditView, ExtraField, TextInput, ContentsTitle },
  props: {
    value: Object,
    length: { type: Number, default: 10 },
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    init() {
      this.model = this.value;
      if (!this.model.list.length) {
        this.model.list.push({ origin: '', value: '' });
      }
      this.model.list = _.map(this.model.list, item => ({ origin: item, value: item.answerName }));
    },
  },
  created() {
    if (this.value) this.init();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
</style>
