<template>
  <div feed-asset-area v-if="value">
    <ImageAsset v-if="value.img" :url="value.img" />
  </div>
</template>

<script>
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'FeedAssetArea',
  components: { ImageAsset },
  props: {
    value: Object,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[feed-asset-area] {.w(100%);
  [image-asset] {.br(0);
  }
}
</style>
