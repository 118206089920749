<template>
  <div vote-examples v-if="ready">
    <VoteExampleItem v-for="(item,id) in server.answers" :key="item.voteAnswersId" :info="item" :exam="id+1" :view="showResult" @updateItem="updateItem" :class="{'owner':info.base&&info.base.owner}" />
  </div>
</template>

<script>
import { getter } from '@shared/utils/storeUtils';
import VoteExampleItem from '@shared/components/vote/VoteExampleItem.vue';

export default {
  name: 'VoteExamples',
  components: { VoteExampleItem },
  lexicon: 'shared.vote',
  props: {
    info: { type: Object, default: null },
    long: { type: Boolean, default: false },
  },
  data() {
    return {
      timer: null,
      pause: false,
      ready: false,
    };
  },
  watch: {
    server() {
      this.resetCountInfo();
    },
  },
  computed: {
    server() {
      return this.info.server;
    },
    isLogin: getter('auth', 'isLogin'),
    showResult() {
      if (this.info.base.owner) return true;
      if (this.info.server.canViewResult) return true;
      // noinspection RedundantIfStatementJS
      if (this.info.server.voteState === 'END' && !this.long) return true;
      return false;
    },
  },
  methods: {
    async updateItem(itemId) {
      if (this.info.server.voteState === 'END') return;
      if (!this.isLogin) return this.$services.auth.oAuthLogin(true);

      if (!this.info.base || this.info.base.owner || this.pause) return;
      this.pause = true;

      const item = this.info.server.answers[itemId - 1];
      await this.selected(item);
      this.$toast(item.isSelected ? '_.cancelled' : '_.voted');
      setTimeout(() => {
        this.pause = false;
      }, 500);
    },
    getPer(count) {
      return Math.sqrt(count ** 3);
    },
    resetCountInfo() {
      if (!this.server) return;
      let total = 0;
      let totalCount = 0;
      const fillValues = [];
      this.info.server.answers.forEach(item => {
        const v = this.getPer(item.count ?? 0);
        fillValues.push(v);
        total += v;
        totalCount += item.count;
      });
      let acc = 0;
      this.info.server.answers.forEach((item, idx) => {
        item.fill = 100 * (fillValues[idx] / total);
        const v = 100 * (item.count / totalCount);
        if (idx === this.info.server.answers.length - 1) {
          item.per = Math.round((100 - acc) * 10) * 0.1;
        } else {
          item.per = Math.round(v * 10) * 0.1;
          acc += v;
        }
        if (item.per.toString().indexOf('.') > -1) item.per = item.per.toFixed(1);
      });
    },
    async selected(item) {
      const { answerName } = item;
      const { voteAnswerId } = item;
      const res = await this.$services.vote.selectedVote({ voteId: this.info.server.voteId, voteAnswerId, answerName });
      this.info.server = res;
    },
    async cancelSelected(item) {
      const { voteAnswerId } = item;
      const res = await this.$services.vote.canceledVote({ voteId: this.info.server.voteId, voteAnswerId });
      this.info.server = res;
    },
  },
  mounted() {
    if (this.server) this.resetCountInfo();
    this.ready = true;
  },

};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[vote-examples] {
  .owner {
    button {cursor: default !important;}
  }
}

</style>
