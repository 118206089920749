<template>
  <div time-selector :class="theme">
    <div class="picker">
      <div class="picker-pointer" :style="{transform: `rotate(${rotate}deg)` }"></div>
    </div>
    <p class="hour">
      <button class="up" @click="add(60)">
        <svg-angle />
      </button>
      <TextInput digit :value="hour" @change="hourChange" :theme="theme" :max-length="maxLength" tabindex="1" />
      <button class="down" @click="add(-60)" tabindex="-1">
        <svg-angle />
      </button>
    </p>
    <p class="minute">
      <button class="up" @click="add(interval, true)" tabindex="-1">
        <svg-angle />
      </button>
      <TextInput digit :value="min" @change="minuteChange" :theme="theme" :max-length="maxLength" tabindex="2" />
      <button class="down" @click="add(-interval,true)">
        <svg-angle />
      </button>
    </p>
    <p class="ampm">{{ ampm }}</p>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';
import TextInput from '@shared/components/common/input/TextInput.vue';
import DragUtils from '@shared/utils/dragUtils';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import ComponentModel from '@shared/mixins/ComponentModel';

export default {
  name: 'TimeSelector',
  components: { SvgAngle, TextInput },
  props: {
    value: {},
    interval: { type: Number, default: 30 },
    theme: String,
  },
  mixins: [ComponentModel({
    event: 'change',
    update: 'modelUpdate',
  })],
  data() {
    return {
      picker: null,
      model: 0,
      display: '12:00 PM',
      time: '00:00',
      hour: '00',
      min: '00',
      ampm: 'AM',
      numRegex: /[^0-9]/g,
      maxLength: 2,
    };
  },
  watch: {
    async model(v) {
      let h = Math.floor(v / 60);
      const HH = `${h}`.padStart(2, '0');
      const m = v - h * 60;
      h = h > 12 ? h - 12 : h;
      const hh = h === 0 ? '12' : `${h}`.padStart(2, '0');
      const mm = `${m}`.padStart(2, '0');
      this.display = `${hh}:${mm} ${this.ampm}`;
      this.time = `${HH}:${mm}`;
      this.hour = HH;
      this.min = mm;
      this.ampm = +HH < 12 ? 'AM' : 'PM';

      await this.$nextTick();
      this.$emit('change', this.time);
    },
  },
  computed: {
    seg() {
      return 1440 / this.interval;
    },
    rotate() {
      return (this.model / 1440) * 360;
    },
  },
  methods: {
    getDeg(x, y) {
      const rect = this.picker.getBoundingClientRect();
      const centerX = rect.left + (rect.width * 0.5);
      const centerY = rect.top + (rect.height * 0.5);
      return Math.atan2(y - centerY, x - centerX) / (2 * Math.PI) + 0.25;
    },
    change({ clientX, clientY }) {
      let deg = this.getDeg(clientX, clientY);
      deg = (deg < 0) ? 1 + deg : deg;
      const level = Math.round(deg * this.seg);
      this.model = level === this.seg ? 0 : level * this.interval;
    },
    modelUpdate() {
      if (!this.value) {
        this.model = 0;
        return;
      }
      const matched = String(this.value).match(/^([01]\d|2[0-3]):([0-5]\d)$/);
      if (!matched) throw new Error(`invalid time format (HH:mm) ${this.value}`);
      this.model = +matched[1] * 60 + (+matched[2]);
    },
    hourChange(value) {
      const hour = +value > 23 ? '0' : value;
      this.model = +hour * 60 + (+this.min);
    },
    minuteChange(value) {
      const minute = +value > 59 ? '0' : value;
      this.model = +this.hour * 60 + (+minute);
    },
    add(value, isMinute) {
      const max = 1440;
      const model = this.model + value;
      const remainder = +this.min % Math.abs(value);

      this.model = (() => {
        switch (true) {
          case model >= max:
            return isMinute ? 0 : this.min;
          case model < 0:
            return isMinute ? max - this.interval : (max - 60) + this.model;
          default:
            return isMinute ? model - remainder : model;
        }
      })();
    },
    wheelInit() {
      const wheel = throttle(e => this.add(e.deltaY < 0 ? this.interval : -this.interval, true), 200);
      this.picker.addEventListener('wheel', e => {
        e.preventDefault();
        e.stopImmediatePropagation();
        wheel(e);
      });
    },
  },
  mounted() {
    this.picker = this.$el.querySelector('.picker');
    DragUtils.enable(this.picker, this.change);
    this.wheelInit();
  },
  beforeDestroy() {
    DragUtils.destroy(this.picker);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[time-selector] { .rel;
  .picker { .wh(120); .p(25); .contain('@{img}/util/time-picker.svg'); .rel; .ib; .vam; .pointer; .-box; .mr(38);
    .picker-pointer { .abs; .lt(0, 0); .wh(120); .bg('@{img}/util/time-line.svg'); .bg-s(4, 38); .bg-xc; .bg-y(24); z-index: 1; .no-repeat; }
  }
  .hour { .mr(25);
    &:after { content: ':'; .block; .abs; .fs(16); .rt(-14, 50%); .t-yc; }
  }
  .hour, .minute { .wh(60, 120); .rel; .-box; .ib; .vat;
    [text-input] { .abs; .lt(0, 50%); .t-yc;
      > input { .tc; }
    }
    button { .abs; .wh(24); .bgc(transparent); .l(50%);
      svg { .w(24);
        .stroke-target { stroke: #a4a3ae; }
      }
      &.up { .t-rxy(180deg, 50%, 0); .t(0); }
      &.down { .t-rxy(0, -50%, 0); .b(0); }
    }
  }
  .ampm { .abs; .rt(0, 50%); .t-yc; .c(@c-base-gray); .fs(16); .bold; }

  &.dark {
    .picker { .bgc(white); .br(50%); }
    .hour:after { .c(white); }
  }
}
</style>
