<template>
  <Modal date-time-modal v-if="ymdModel && timeModel" :theme="options.theme || 'light'" @close="close">
    <div class="box" :class="{time:options.useTime}">
      <ul class="result">
        <li>
          <TextInput readonly :value="ymd" :theme="options.theme" />
        </li>
        <li>
          <TextInput readonly :value="enHm" :theme="options.theme" />
        </li>
      </ul>
      <div class="calendar">
        <DateSelector v-model="ymdModel" @input="onYmdInput" :rangeStart="rangeStart" :rangeEnd="rangeEnd" :theme="options.theme" />
      </div>
      <div class="timer" v-if="options.useTime">
        <TimeSelector v-model="timeModel" :interval="5" :theme="options.theme" />
      </div>
      <p class="bts-confirm" :class="{'hide-return': !options.useReturn}">
        <button @click="$emit('resolve',null)">{{ $t('cancelSetting') }}</button>
        <button @click="close">{{ $t('cancel') }}</button>
        <button @click="$emit('resolve',millisecond)">{{ $t('confirm') }}</button>
      </p>
    </div>
  </Modal>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import Modal from '@shared/components/common/Modal.vue';
import DateSelector from '@shared/components/common/date/DateSelector.vue';
import TimeSelector from '@shared/components/common/date/TimeSelector.vue';
import { getEnHM, getHM, getYMD, getTime } from '@shared/utils/timeUtils';

export default {
  name: 'DateTimeModal',
  components: { TimeSelector, DateSelector, Modal, TextInput },
  props: {
    options: { type: Object, default: null },
  },
  data() {
    return {
      ymdModel: null,
      timeModel: null,
    };
  },
  computed: {
    ampm() {
      let v = this.options.time;
      v = Number(v.slice(0, 2));
      return v < 12 ? 'AM' : 'PM';
    },
    ymd() {
      return getYMD(`${this.ymdModel} ${this.timeModel}`);
    },
    hm() {
      return getHM(`${this.ymdModel} ${this.timeModel}`);
    },
    enHm() {
      return getEnHM(`${this.ymdModel} ${this.timeModel}`);
    },
    millisecond() {
      return getTime(`${this.ymdModel} ${this.timeModel}`);
    },
    rangeStart() {
      return this.options?.rangeStart;
    },
    rangeEnd() {
      return this.options?.rangeEnd;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onYmdInput() {
      this.timeModel = this.hm;
    },
  },
  mounted() {
    this.ymdModel = this.options.time ? getYMD(this.options.time) : getYMD();
    this.timeModel = this.options.time ? getHM(this.options.time) : this.options.timeFallback ?? getHM();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[date-time-modal] { .z(101);
  &.light .panel {.bgc(transparent); .br(0); box-shadow: none;
    .header { .hide; }
    .scroll-area {.p(0);}
  }
  &.dark {
    .box {
      .result, .calendar, .timer, .bts-confirm { .bgc(#161a1d);}
      .bts-confirm {
        button {
          &:nth-of-type(3) {.bgc(#c71f1f); .c(white);}
        }
      }
    }
  }
  button {.bgc(transparent);}
  .box {
    .result {
      &:after {.cnt;.abs; .lb(50%, -1); .t-xc; .bgc(@c-base-gray); width: calc(100% - 30px); .h(2);}
    }
    .result { .ib-list; .p(20, 15); .bgc(white); .br(20); .rel;
      li {.w(50%); .p(0, 5); .-box; .rel;
        [text-input] input { .pl(48) !important; .-box;}
        &:before {.cnt; .abs; .wh(24, 24); .contain('@{img}/icon/icon-date-default.svg'); .lt(12, 50%); .t-yc; z-index: 2;}
        &:nth-of-type(2):before { .contain('@{img}/icon/icon-time-default.svg');}
      }
    }
    .calendar {.br-t(20); .bgc(white); overflow: hidden; .rel; .p(0, 20); .pb(20); .-box; .min-h(364);
    }
    .timer { .wh(100%, 160); .br(20); .bgc(white); .ib-list; overflow: hidden; .-box; .rel; .pt(40);
      [time-selector] { .wh(350, 120); .abs; .lt(50%, 40); .t-xc;}
    }
    .bts-confirm { .bgc(white); .tc; .pt(40); .-box; .br-b(20); .pb(20);
      &.hide-return {
        button { .w(160);
          &:nth-of-type(1) { .hide; }
        }
      }
      button {.wh(104, 48); .fs(15, 48); .br(24); .bgc(@c-base-gray);
        &:nth-of-type(1) {.c(#787781);}
        &:nth-of-type(2) {.c(#787781); .m(0, 10);}
        &:nth-of-type(3) {.c(white); .bgc(black); }
      }
    }
    &.time {
      .calendar {.br(20);}
      .timer {.br-t(20);}
    }
  }
}
</style>
