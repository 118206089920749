<template>
  <div pagination>
    <a @click="first" class="first" :class="{disabled: pageNow < 2}"></a> <a @click="prev" class="prev" :class="{disabled: pageNow < 2}"></a> <a v-for="page in pageList()" :key="page" :class="{on: page === pageNow }" @click="$emit('go', page)">{{ page }}</a> <a @click="next" class="next" :class="{disabled: totalPages === pageNow}"></a> <a @click="end" class="end" :class="{disabled: totalPages === pageNow}"></a>
  </div>
</template>
<script>
export default {
  props: {
    option: Object,
  },
  data() {
    return {
      totalPages: 0,
      pageNow: 0,
      pageStart: 0,
      pageEnd: 0,
    };
  },
  methods: {
    first() {
      if (this.pageNow === 1) return;
      this.$emit('go', 1);
      this.$emit('change', 1);
    },
    prev() {
      if (this.pageNow === 1) return;
      this.$emit('go', this.pageNow - 1);
      this.$emit('change', this.pageNow - 1);
    },
    next() {
      if (this.pageNow === this.totalPages) return;
      this.$emit('go', this.pageNow + 1);
      this.$emit('change', this.pageNow + 1);
    },
    end() {
      if (this.pageNow === this.totalPages) return;
      this.$emit('go', this.totalPages);
      this.$emit('change', this.totalPages);
    },
    pageList() {
      const result = [];
      if (!this.$props) {
        result.push(1);
        return result;
      }
      if (this.$props.option) {
        const len = 10;
        const c = this.$props.option.pageNo - 1;
        const t = this.$props.option.pageCount;
        const s = (Math.floor(c / len) * len) + 1;
        const e = s + (t - s > len - 1 ? len - 1 : t - s);
        this.pageNow = this.$props.option.pageNo;
        this.totalPages = t;
        this.pageStart = s;
        this.pageEnd = e;
        for (let i = s; i <= e; i += 1) result.push(i);
      } else {
        this.pageNow = 0;
        this.totalPages = 0;
        this.pageStart = 0;
        this.pageEnd = 0;
        result.push(1);
      }
      return result;
    },
  },
};
</script>
<style lang="less">
@import '~@shared/less/proj.less';

[pagination] { .tc;
  a { .rel; .ib; .vam; .wh(24, 24); .fs(13, 24); .m(0, 8); .medium; .c(rgba(0, 0, 0, .5)); .br(4); .tc; .pointer;
    &.on { .c(black);
      &:before { .cnt; .abs; .bgc(black); .wh(8, 1); .lb(7, 0); }
    }
    &:hover { .bgc(#ebebf0); .wh(24);}
    &.disabled {.o(0);}
    &:before {.cnt; .abs; .lt(0.3, 0.3); .wh(24); .o(0.4);}
    &.first {.ml(0);.mr(0); .rel;
      &:before {.contain('@{icon}/chevron-double-left.svg');}
    }
    &.end {.ml(0);.mr(0); .rel;
      &:before { .contain('@{icon}/chevron-double-right.svg');}
    }
    &.prev {.ml(2);.mr(8); .rel;
      &:before { .contain('@{icon}/chevron-left.svg');}
    }
    &.next { .ml(8);.mr(2); .rel;
      &:before { .contain('@{icon}/chevron-right.svg');}
    }
  }
}
</style>
