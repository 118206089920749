<template>
  <div vote-guide>
    <div class="contents-holder">
      <div class="org" v-if="info.base.owner">
        <h3 v-html="$t('_.autoCreate', {val: matchType})" />
        <button class="btn-modify" @click="openInputModal">{{ $t('_.editVote') }}</button>
      </div>
      <div class="user" v-else>
        <img class="man" src="/shared/img/man.svg" />
        <h3 v-html="$t('_.autoCreate', {val: matchType})" />
      </div>
    </div>
  </div>
</template>

<script>

import CreateVoteModal from '@shared/components/vote/modal/CreateVoteModal.vue';

export default {
  name: 'VoteGuide',
  lexicon: 'shared.vote',
  components: {},
  props: {
    info: { type: Object, default: null },
  },
  data() {
    return {};
  },
  computed: {
    matchType() {
      if (this.info.server.option.voteOpenType === 'SEMI_FINAL') return '4';
      else if (this.info.server.option.voteOpenType === 'QUARTER_FINAL') return '8';
      else return '';
    },
  },
  watch: {},
  methods: {
    async openInputModal() {
      this.info.server = await this.$modal(CreateVoteModal, { model: this.info, modify: true });
    },
  },
  mounted() {
  },
};

</script>

<style lang="less">
@import '~@shared/less/proj.less';
[vote-guide] { .w(100%); .h(252); .bgc(white); .br(20);
  .contents-holder { .flex-center; .wh(100%);
    > div {
      &.org {
        h3 { .fs(20); .lh(32); .c(black); .w(100%);}
        .btn-modify { .block; .mt(20); width: calc(100%); .h(56); .fs(16); .bgc(@c-base-gray); .br(40); .c(black);}
      }
      &.user { .tc;
        img { .wh(90); .br(200); .ib; }
        h3 { .mt(15); .fs(16); .c(black);}
      }
    }
  }
  @media (@tl-up) {
    .wh(375, 272);
    .contents-holder {
      > div {
        &.org {
          h3 { .fs(20); .lh(32); .c(black); .tc;}
          .btn-modify { .wh(240, 56);}
        }
        &.user {
          img { .wh(120);}
          h3 { .mt(20); .fs(20);}
        }
      }
    }
  }
  @media (@ds-up) {
    .h(478);
  }
}

</style>
