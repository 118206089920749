// UPLOAD_URL: 'https://4urj3951sj.execute-api.ap-northeast-2.amazonaws.com/dev/signedurl',
export const UPLOAD_URL = 'https://4urj3951sj.execute-api.ap-northeast-2.amazonaws.com/dev/getSignedUrl';
export const BASEURL_ORIGINAL = 'https://s3.ap-northeast-2.amazonaws.com/';
export const BASEURL = 'https://d3hqehqh94ickx.cloudfront.net';
export const FILE_EXISTS_URL = 'https://4urj3951sj.execute-api.ap-northeast-2.amazonaws.com/';
export const PATH = 'temp/';
export const BUCKET = 'versuson-files';
export const EXPIRE = 90;

export default {
  UPLOAD_URL,
  BASEURL,
  BASEURL_ORIGINAL,
  FILE_EXISTS_URL,
  PATH,
  BUCKET,
  EXPIRE,
};
