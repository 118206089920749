import _get from 'lodash/get';

export const getFeedOptions = kv => ({
  id: kv.id,
  text: kv.text,
  title: kv.title,
  link: kv.link,
  category: kv.category.value,
  useComment: kv.useComment,
  img: {
    key: 'load',
    forSubmit: kv.image,
  },
  isNotice: kv.isNotice,
});

export const getPersonalOptions = (kv, specialId) => ({
  name: _get(kv, 'user.writerChannelInfo.channelName', ''),
  isWriter: _get(kv, 'user.writerChannelInfo.isWriter', false),
  intro: _get(kv, 'text', ''),
  thumb: _get(kv, 'user.writerChannelInfo.channelThumbnail', ''),
  date: _get(kv, 'createdAt', new Date()),
  specialId,
});
