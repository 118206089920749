<template>
  <div tab-select>
    <button
      v-for="(item, idx) in items"
      :key="`select-${idx}`"
      @click="selected(idx)"
      :class="['tab-select__btn', idx === selectedIndex ? 'active' : '']"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TabSelect',
  props: {
    items: { type: Array, default: () => [] },
    value: { type: Number, default: 0 },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  watch: {
    value: 'update',
  },
  computed: {},
  methods: {
    update() {
      this.selectedIndex = this.value;
    },
    selected(id) {
      this.selectedIndex = id;
      this.$emit('input', id);
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tab-select] { .rel; .ib; .bgc(white); .fs(0); .br(4); .h(40);
  button { .rel; z-index: 2; .h(100%); .p(0, 12); transition: 0.25s; .c(#787781); background: transparent; .fs(13);
    &.active { .bgc(#191919); .c(#ebebf0); .br(4); }
  }
;
}
</style>
