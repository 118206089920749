<template>
  <div modal :class="theme">
    <div class="dim" @click="close"></div>
    <div class="panel" :class="size">
      <div class="header">
        <slot name="title" />
        <a class="close" @click="close">
          <svg-x />
        </a>
      </div>
      <div class="scroll-area">
        <slot />
      </div>
      <slot name="floating" />
    </div>
  </div>
</template>

<script>
import SvgX from '@shared/graphics/svg-x.vue';
import modal from '@shared/mixins/modal';

export default {
  name: 'Modal',
  components: { SvgX },
  mixins: [modal],
  watch: {
    $route() {
      if (!this.noRouteClose) this.$emit('close');
    },
  },
  props: {
    theme: { type: String, default: 'light' },
    size: { type: String, default: 'large' },
    noRouteClose: { type: Boolean, default: false },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[modal] { .abs; .f; .flex-center;
  > .dim { .abs; .f; .bgc(@c-b07); .lt; }
  > .panel { .rel; .br(20); .wh(100%, auto); .crop; box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.5); .max-h(100%);
    > .header { .p(40, 20, 0); .rel;
      .close { .abs; .rt(20, 20); .z(100);
        svg { .block; }
      }
    }
    > .scroll-area { .p(0, 20); .scroll-y;}
    &.normal { .p(0, 20);
      > .header { .p(22, 0, 12); .-b(@c-base-gray); .mb(15);
        .close { .r;}
        h2 {.fs(16);}
      }
      .scroll-area { .p(0, 0, 30); }
    }
    &.medium { .pb(40);
      .scroll-area { .p(0, 26); }
    }
  }
  &.light {
    > .panel { .bgc(#fff);
      > .header {
        .close {
          svg {
            .stroke-target { stroke: #191919; }
          }
        }
      }
    }
  }

  &.dark {
    > .panel { .bgc(#161a1d);
      > .header {
        .close {
          svg {
            .stroke-target { stroke: #fff; }
          }
        }
      }
    }
  }

  &.transparent {
    > .panel.large { .bgc(transparent); box-shadow: none; .br(0);
      > .header { .p(20, 0);
        .close { .rt(0, 24); }
        svg { .bgc(@c-base-gray); .br(4);
          .stroke-target { stroke: @c-text-gray; }
        }
      }
      .scroll-area {.p(0);}
    }
  }

  @media (@ml-up) {
    .p(10);
    > .panel { .w(394); }
  }

  @media (@tp-up) {
    > .panel.large { .w(460);
      > .header { .p(40, 40, 0); }
      > .scroll-area { .p(0, 40); }
    }
  }
  @media (@tl-up) {
  }
}
</style>
