<template>
  <div vote-list :class="[{'open':extension},{'feed': isFeed} , {'view-only':viewOnly}]">
    <div class="title">
      <h2>{{ title || $t('_.vote') }}
        <button class="btn-delete" v-if="viewOnly && info.base.owner" @click="!usePrefix ? deleteVote() : `${refName}.deleteVote()`" />
        <button class="btn-make" v-else-if="!isFeed && isEnd && !viewOnly && info.base.owner" @click="createVote" />
      </h2>
      <button v-if="info.base && (info.base.owner && info.server.voteState !== 'END')" class="btn-detail" @click="$emit('modify')" />
      <p class="options">
        <em class="date">{{ $t('_.endDue', { val: limitTime }) }}</em>
        <em class="count">{{ $t('_.participantCount', { val: info.server.totalVotingCount }) }}</em>
        <button class="btn-reload" @click="reload" v-if="!viewOnly" />
      </p>
    </div>
    <div class="complete" v-if="isEnd">
      <h4>{{ $t('_.endVoteMsg') }}</h4>
      <p class="option">
        <button class="btn-download" v-if="info.base.owner" @click="!usePrefix ? downloadApply() : `${refName}.downloadApply()`" />
        <em :class="{ answered: info.server.isAnswered }" v-else>{{ info.server.isAnswered ? $t('_.completeParticipate') : $t('_.noParticipate') }}</em>
      </p>
    </div>
    <div class="description" :class="{'on':openDescription}" v-else-if="info.server.description">
      <button @click="openDescription = !openDescription">{{ $t('_.voteDetail') }}<em>
        <svg-angle />
      </em></button>
      <p>{{ info.server.description }}</p>
    </div>
    <div class="status">
      <span><em :class="{'end': isEnd, 'voting': !isEnd}">{{ isEnd ? $t('_.status.end') : $t('_.status.running') }}</em>{{ info.server.subject }}</span>
    </div>
    <div class="item-holder" :class="{ long }">
      <VoteExamples :info="info" :long="long" />
      <div v-if="!viewOnly && !isFeed && seeMore " class="bar">
        <button class="btn-toggle" @click="toggleExtension">
          <svg-angle />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VoteExamples from '@shared/components/vote/VoteExamples.vue';
import ConfirmModal from '@shared/components/common/modal/ConfirmModal.vue';
import CreateVoteModal from '@shared/components/vote/modal/CreateVoteModal.vue';
import Specific from '@shared/types/Specific';
import { downloadFile } from '@shared/utils/fileUtils';
import SvgAngle from '@shared/graphics/svg-angle.vue';

const refPrefix = '__vote_list__';
let refSeq = 0;

export default {
  name: 'VoteList',
  lexicon: 'shared.vote',
  components: { SvgAngle, VoteExamples },
  props: {
    info: Specific,
    viewOnly: Boolean,
    usePrefix: Boolean,
    title: { type: String },
  },
  data() {
    return {
      model: null,
      extension: false,
      openDescription: false,
      refId: null,
    };
  },
  computed: {
    limitTime() {
      const m = moment(this.info.server.voteEndDatetime);
      return this.matchedMediaDevice === 'M' ? m.format(this.$t('_.dateShort')) : m.format(this.$t('_.dateFull'));
    },
    seeMore() {
      if (!this.info.server || !this.info.server.answers) return false;
      return this.info.server.answers.length > this.votingVisibleNum;
    },
    votingVisibleNum() {
      return this.matchedMediaDevice === 'D' ? 4 : 3;
    },
    isFeed() {
      return this.info.base && (this.info.base.type === 'LVUP_SPECIAL_FEED' || this.info.base.type === 'LEAGUE_FEED');
    },
    isEnd() {
      return this.info.server.voteState === 'END';
    },
    refName() {
      return refPrefix + this.refId;
    },
    maxHeight() {
      return 0;
    },
    long() {
      return this.info.server.answers.length > 16;
    },
  },
  methods: {
    toggleExtension() {
      this.extension = !this.extension;
    },
    reload() {
      this.info.update = !this.info.update;
    },
    async downloadApply() {
      const res = await this.$services.vote.getDownload(this.info.server.voteId);
      downloadFile(res);
    },
    async deleteVote() {
      await this.$modal(ConfirmModal, { title: this.$t('_.deleteVoteResult'), body: this.$t('_.foreverDelete') });
      await this.$services.vote.deleteVote({ specialId: this.info.base.specialId, voteId: this.info.server.voteId });
      this.$emit('update');
    },
    async createVote() {
      await this.$modal(ConfirmModal, { title: this.$t('_.createNewVote'), body: this.$t('_.autoMove') });
      this.info.server = await this.$modal(CreateVoteModal, { model: this.info });
    },
  },
  mounted() {
    this.extension = this.isFeed;
    if (this.usePrefix) {
      this.refId = refSeq++;
      window[refPrefix + this.refId] = this;
    }
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[vote-list] { .rel; .max-h(400); .crop; .p(0, 10);
  &.view-only { max-height: none; .pb(10);
    .title {
      .options { .pr(0); .r(20);
        em {.mr(0);}
      }
    }
  }
  .title { .-b(@c-base-gray, 1px);
    h2 { .c(black); .pt(15); .pb(15); .fs(18); .ib;
      button {.wh(24); .ib; .vam; .br(6); .bgc(@c-base-gray); .mr(8); .mt(-2); .ml(4); .rel;
        &:before { .cnt; .abs; .lt(50%, 50%); .t-xyc; .wh(10); .contain('@{icon}/icon-plus-gray.svg'); .o(.5);}
        &.btn-delete:before { .contain('@{icon}/ico_trash_dark.svg'); .wh(23, 24); .mt(0); .ml(0); }
      }
    }
    .btn-detail { .ml(10); .wh(40); .bgc(@c-base-gray); .br(20); .vam; .mb(10);
      &:hover {
        &:before { .o(.8);}
      }
      &:before { .cnt; .contain('@{icon}/ico_dot3.svg'); .wh(24); .tc; .ml(7.5); .o(.5);}
    }
    .options {.rt(15, 12); .abs; .pr(20);
      em { .fs(10); .c(@c-placeholder); .-b(rgba(0, 0, 0, .5), 1px); .mr(10); .block; .tr;
        &.date { .rt(10, 22);}
        &.count { .t(38); .-b;}
      }
      .btn-reload { .wh(24, 24); .br(6); .bgc(@c-base-gray);.abs; .rt(0, 2);
        &:before { .cnt; .wh(24); .o(.5); .contain('@{icon}/ico_refresh.svg');}
        &:hover {
          &:before { .o(.8);}
        }
        &:active {
          &:before { .o(1);}
        }
      }
    }
  }
  .complete { .bgc(@c-title-black); .br(12); .mh-c; .p(18, 20); .-box; .mt(16); .rel;
    h4 {.fs(16, 20px); .c(white); .pr(100); word-break: keep-all; .ellipsis; white-space: nowrap;}
    .count {.fs(14); .c(#ffb600); .medium; .block; .mt(4);}
    .option { .abs; .rt(20, 50%); .t-yc;
      .btn-download { .bgc(white); .wh(72, 32); .br(29); .rel;
        &:after { .cnt; .contain('@{icon}/ico_download.svg'); .wh(20); .abs; .lt(50%, 50%); .t-xyc;}
      }
      em {.wh(auto, 32); .p(0,6); .min-w(72); .bgc(#2c81ff); .br(8); .fs(12, 32px); .tc; .c(white); .block; .medium;}
    }
  }

  .description { .tc; .mt(20); .rel;
    > button { .fs(13, 48px); .wh(100%, 48); .p(0, 4, 0, 12); .-box; .-a(rgba(0, 0, 0, .1)); .br(12); .block; .tl; .rel; .z(11); .bgc(white);
      > em { .wh(40); .br(12); .bgc(@c-base-gray); .abs; .rt(4, 3); .bg-c; .no-repeat; .flex-center;
        > svg { .w(12); stroke: @c-text-dark;}
      }
    }
    > p {.fs(13, 19px); .c(@c-title-black); white-space: pre-line; .tl; .br-b(12); .-a(rgba(0, 0, 0, .1)); .bgc(white); .lt(0, 98%); .abs; .z(100); .p(10, 12); .-box; transition: .2s; .t-y(-10%); .o(0); pointer-events: none; .w(100%); .max-h(150); overflow: auto;}
    &.on {
      > button { .br-t(12);
        > em {
          > svg {.t-r(180deg);}
        }
      }
      > p { .o(1); .t-y(0); pointer-events: auto;}
    }
  }

  .status { .mt(10); .bgc(@c-base-gray); .br(10); .h(68); .p(0, 40); .-box; .flex-center;
    > span { .tc; .fs(16); .c(black); .ellipsis(2);
      em { .ib; .c(white); .fs(11, 18px); .h(18); .p(0, 6); .br(20); .bgc(@c-title-black); .vat; .mt(2); .mr(5);
        &.voting { background: linear-gradient(180deg, #953ee9 0%, #5f1aaa 100%); }
      }
    }
  }
  .item-holder { .pt(20);
    &.long [vote-example-item] { width: calc(50% - 6px); .ib;
      &:nth-of-type(even) { .ml(12); }
    }
    .bar { .abs; .rb(0, -15); .w(100%); .h(35); .br-b(20); .bgc(white); box-shadow: 0 -6px 4px 0 rgba(0, 0, 0, 0.1); .z(2);
      .btn-toggle { .wh(40); .br(12); .bgc(@c-title-black); .abs; bottom: 15px; left: 46%; z-index: 3;
        &:hover {
          &:before { .o(.8);}
        }
        svg { .w(16); stroke: white; .mb(2);}
      }
    }
  }
  &.open { overflow: visible; .rel; .pb(40); .max-h(none);
    .item-holder {
      .bar {.rb(0, -20);
        .btn-toggle {
          svg { .t-r(180deg);}
        }
      }
    }
  }
  &.feed { max-height: none; .mb(0) !important; .pb(0) !important;}

  @media (@tp-up) {
    .p(0, 20);
    .max-h(374);
    &.feed { .max-h(330); .max-w(100%);
      .item-holder { .clear;
        [vote-example-item] { width: calc(50% - 10px); .ib;
          &:nth-of-type(even) { .ml(20); }
        }
        &.long {
          [vote-example-item] { width: calc(25% - 9px); .ib; .ml(12);
            &:nth-of-type(4n + 1) { .ml(0); }
          }
        }
      }
      &.open { max-height: none;}
    }
    &.open { .h(100%); .pb(36);
      .item-holder {
        .bar {.rb(0, -20);}
      }
    }
  }
  @media (@ds-up) {
    .max-h(458);
  }
}
</style>
