import _ from 'lodash';

export const teamItem = {
  stageRosterId: '',
  playoffSeed: 1,
  roundSeed: {
    0: 1,
  },
  arenaApplyId: '',
  team: {
    teamId: null,
    name: '',
    image: '',
    gameId: null,
  },
  member: [],
};

const getRound = list => {
  const len = list.length;
  let tLen = 4;

  while (tLen < len) {
    tLen *= 2;
  }
  return tLen;
};

const getBlank = () => {
  const t = _.cloneDeep(teamItem);
  t.include = true;
  t.blank = true;
  return t;
};

export const listToRow = list => {
  let clone = list.slice();
  clone = _.filter(clone, item => !item.exclude && item.include);

  if (clone.length === 0) return [];
  const len = clone.length;
  const round = getRound(clone);

  for (let i = 0; i < round; i += 1) {
    if (i >= len) clone.push(getBlank());
    clone[i].id = i;
  }
  return clone;
};

export const addInclude = items => {
  const len = items.length;
  const round = getRound(items);
  const soloMatchCount = round - len;
  const group = Array.from({ length: round / 2 }, () => []);

  items.forEach((item, i) => {
    if ((len - i) > soloMatchCount) {
      const idx = Math.floor(i / 2);
      group[idx].push(item);
    } else {
      const idx = group.length - (len - i);
      group[idx] = [item, getBlank()];
    }
  });
  return listToRow(_.flattenDepth(group, 2));
};

export const removeInclude = ({ row, item }) => {
  const round = row.length;
  const changeRound = getRound(_.filter(row, o => !o.blank && o.team.name !== item.team.name));

  if (changeRound < round) {
    _.remove(row, o => o.blank || o.team.name === item.team.name);
    return row;
  }
  row[_.findIndex(row, o => o.team.name === item.team.name)] = getBlank();

  return listToRow(row);
};

export const recommendIncludeList = row => {
  const usedList = _.shuffle(_.filter(row, item => !item.blank));
  const dummyList = _.filter(row, item => item.blank);

  let group = [...usedList, ...dummyList];
  group = _.flattenDepth(_.map(_.groupBy(
    group.map((v, i) => ({ v, i: i % Math.ceil(group.length / 2) })),
    o => o.i,
  ), a => a.map(o => o.v)), 2);
  return listToRow(group);
};

export const rowToPair = row => _.map(
  _.groupBy(
    row.map((v, i) => ({ v, i: Math.floor(i / 2) })),
    o => o.i,
  ),
  a => a.map(o => o.v),
);

const matchSorting = ({ tree, pair }) => {
  _.forEach(pair, (item, id) => {
    /** 홈 시드 세팅 */
    if (item[0].team.name !== '') item[0].playoffSeed = item[0].id + 1;
    else item[0].playoffSeed = 0;
    /** 어웨이 시드 세팅 */
    if (item[1].team.name !== '') item[1].playoffSeed = item[1].id + 1;
    else item[1].playoffSeed = 0;

    [tree[1][id].home, tree[1][id].away] = item;
    tree[1][id].winByDefault = true;
  });
  return tree;
};

export const getSingleMatches = ({ tree, pair }) => {
  const third = _.remove(tree[1], o => o.index === 0 && o.rank === 4);
  if (third.length > 0) tree[1].push(third[0]);
  return matchSorting({ tree, pair });
};

export const getSingleDummyBody = ({ pair, round, thirdPlaceMatch = null, save = false }) => {
  let len = pair.length;
  if (len === 0) return null;

  round = Number(round);
  let boValue = 0;
  let seedId = 0;
  const list = [];
  while (len >= 1) {
    boValue += 1;
    len /= 2;
  }
  _.forEach(pair, item => {
    list.push({
      home: {
        seed: ++seedId,
        id: !save || item[0].arenaApplyId.length === 0 ? null : item[0].arenaApplyId,
      },
      away: {
        seed: ++seedId,
        id: !save || item[1].arenaApplyId.length === 0 ? null : item[1].arenaApplyId,
      },
    });
  });

  return {
    isPreview: !save,
    bo: {
      bo: _.fill(new Array(boValue), round),
      thirdPlaceMatchBo: thirdPlaceMatch ? round : null,
    },
    applications: [list],
  };
};

export const getDoubleMatches = ({ tree, pair }) => matchSorting({ tree, pair });

export const getDoubleDummyBody = ({ pair, round, save = false }) => {
  let len = pair.length;
  if (len === 0) return null;

  round = Number(round);
  let boValue = 0;
  let seedId = 0;
  const list = [];
  while (len >= 1) {
    boValue += 1;
    len /= 2;
  }
  _.forEach(pair, item => {
    list.push({
      home: {
        seed: ++seedId,
        id: !save || item[0].arenaApplyId.length === 0 ? null : item[0].arenaApplyId,
      },
      away: {
        seed: ++seedId,
        id: !save || item[1].arenaApplyId.length === 0 ? null : item[1].arenaApplyId,
      },
    });
  });

  return {
    isPreview: !save,
    bo: {
      winnerBo: _.fill(new Array(boValue + 1), round),
      loserBo: _.fill(new Array((boValue - 1) * 2), round),
      finalBo: round,
    },
    applications: [list],
  };
};

/**
 * 오픈오거나이저 ToggleEditView 에 입력된 값을 판단하여 boolean 반환
 * 케이스를 고정할 수 없어서 현재 사용되는 기준으로 조건을 분기하여 적용함.
 * @param {any} kv
 */
export const getUsableCheck = kv => {
  if (!kv) return false;
  switch (typeof (kv)) {
    case 'string':
      return kv === 0 || kv.length > 0;
    case 'object':
      if (kv.file) return true;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return getObject(kv);
    case 'number':
      return true;
    default:
      return false;
  }

  function getObject(kv) {
    let obj;
    let
      result = true;
    if (!kv || kv.length === 0) return false;

    if (kv.length > 0) {
      if (kv[0] && kv[0].length >= 0) { // 이중배열 예외처리.
        result = false;
        _.forEach(kv, item => {
          if (item && item.length !== 0) {
            _.forEach(item, child => {
              if (getUsableCheck(child)) result = true; // 한 개만 입력돼도 사용으로 판단.
            });
          }
        });
        return result;
      }
      result = true;
      _.forEach(kv, item => {
        if (!getUsableCheck(item)) result = false;
      });
      return result;
    }
    // eslint-disable-next-line prefer-const
    obj = kv;

    if (obj === null || obj === undefined || Object.keys(obj).length === 0) return false;
    _.forIn(obj, v => {
      if (typeof (v) === 'object') {
        if (!getUsableCheck(v)) result = false;
      } else if (v === null || v === undefined || v.length === 0) {
        result = false;
      }
    });
    return result;
  }
};
