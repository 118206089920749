<template>
  <div commercial v-if="$route.params.id !== 'val' && list && list.length">
    <h4>광고</h4>
    <p class="list">
      <a
        v-for="(item, index) in list"
        :key="item.id"
        :href="item.styles.link"
        @click="onClickBanner(index)"
        target="_blank"
      >
        <ImageAsset :url="getUrl(item.styles.bannerImage, item.styles.mobileBannerImage)" />
      </a>
    </p>
  </div>
</template>

<script>
import gtag from '@shared/mixins/gtag';
import { getCloudFileUrl } from '@/utils/url';
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'Commercial',
  components: { ImageAsset },
  mixins: [gtag],
  data() {
    return {
      list: null,
    };
  },
  methods: {
    getUrl(img, mImg) {
      return window.innerWidth < 1024 ? getCloudFileUrl(img) : getCloudFileUrl(mImg);
    },
    onClickBanner(index) {
      this.trackEvent(`${this.$route.meta.leagueCode}_Newsfeed_sponsor${index + 1}`, 'click', 'PUBG');
    },
  },
  async created() {
    this.list = await this.$services.channel.getCommercialList(this.$route.meta.leagueCode);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[commercial] { .p(20); .bgc(#ebebf0); .br(8);
  h4 {.fs(14); .mb(16); .font-spoqa;}
  .list {
    a {.wh(100%, 176); .bgc(#d4d4d8); .block; .mt(16); .cover; .bg-c;
      &:nth-of-type(1) {.mt(0);}
      [image-asset] {.br(0); .h(100%);}
    }
  }
  @media (@tp-up) {
    .list { .ib-list;
      a { width: calc(50% - 8px); .ib; .mt(0);
        &:nth-of-type(1) {.mr(16);}
      }
    }
  }
  @media (@ds-up) {
    .list {
      a { .wh(100%, 160); .mt(8);}
    }
  }
}

</style>
