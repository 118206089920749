<template>
  <div extra-field>
    <p v-for="(item,id) in model" :key="id">
      <ValidationProvider :name="validationName" :rules="rules" v-slot="{errors}" :vid="String(id)">
        <TextInput :placeholder="`${placeholder}${id+1}`" v-model="item.value" @input="change" :class="[{'remove': model.length > 1},{error:errors.length}]" />
      </ValidationProvider>
      <button v-if="model.length > 1" @click="remove(id)" />
    </p>
    <button class="btn-add" @click="addExplainList" :disabled="model.length >= max" @enter="addExplainList">항목 추가</button>
  </div>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';

export default {
  name: 'ExtraField',
  components: { TextInput },
  props: {
    value: { type: Array, default: null },
    placeholder: { type: String, default: '' },
    max: { type: Number, default: 5 },
    mandatory: { type: Boolean, default: false },
    validationName: { type: String, default: '' },
    rules: String,
  },
  data() {
    return {
      model: [{ value: '' }],
    };
  },
  watch: {
    value: 'updateModel',
  },
  methods: {
    addExplainList() {
      this.model.push({ value: '' });
    },
    removeExplainList(id) {
      this.model.splice(id, 1);
    },
    updateModel() {
      if (this.value) this.model = this.value;
    },
    change() {
      this.$emit('input', this.model);
    },
    remove(id) {
      this.model.splice(id, 1);
    },
  },
  mounted() {
    this.updateModel();
  },
};

</script>

<style lang="less">
@import '~@shared/less/proj.less';
[extra-field] { .pb(12);
  > p { .rel;
    [text-input] { .mb(6);
      &.remove {width: calc(100% - 30px);}
    }
    button { .wh(24); .rt(0, 50%); .t-yc; .br(6); .abs; .bgc(@c-base-gray);
      &:before { .cnt; .wh(22); .contain('@{icon}/icon-minus.svg'); .ib; .tc; }
    }
  }
  .btn-add { .wh(93, 32); .bgc(black); .rel; .tc; .br(16); .fs(13); color: white; .mh-c; .block; .mt(12); .pl(20);
    &:before { .cnt; .abs; .wh(24); .lt(5, 50%); .t-yc; .contain('@{icon}/icon-plus-white.svg');}
  }
}
</style>
