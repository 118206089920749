import _ from 'lodash';

const map = [];
let observer;

export const addResize = ($el, $callback) => {
  map.push({ el: $el, callback: $callback });
  if(!observer) {
observer = new ResizeObserver(t => {
    if(!t) return;
    _.forEach(map, o => {
      const rt = _.find(t, e => e.target === o.el);
      if(rt) o.callback();
    });
  });
}
  observer.observe($el);
};

export const removeResize = ($el, $callback) => {
  if(!observer) return;
  const target = _.find(map, o => o.el === $el && o.callback === $callback);
  if(target) observer.unobserve($el);
};
