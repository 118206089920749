import _trim from 'lodash/trim';
import { BASEURL as CLOUD_BASE_URL } from '@/constants/awsConst';

export const checkUrl = str => {
  // 기존에 사용하던 regex는 주석처리함
  // const isUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(
  //   str
  // );
  const isUrl = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm.test(str);
  return isUrl;
};

export const getCloudFileUrl = cloudFilePath => {
  const trimmedBaseUrl = _trim(CLOUD_BASE_URL, '/');
  const trimmedPath = _trim(cloudFilePath, '/');
  return [trimmedBaseUrl, trimmedPath].join('/');
};
