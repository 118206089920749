<template>
  <span text-box :class="[{'focus':focusOn, readonly},theme]">
    <textarea class="field" ref="textBox" rows="1" @input="change" @click="$emit('click')" :placeholder="placeholder" :value="t0" :maxLength="maxLength" :readonly="readonly"
              @keydown.enter="$emit('enter')" @blur="blur" @focus="focus"/>
    <span v-if="!t0" class="placeholder" v-html="convertPlaceholder"></span>
  </span>
</template>
<script>
export default {
  name: 'TextBox',
  props: {
    value: { default: '' },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    maxLength: { type: [String, Number], default: null },
    autoHeight: { type: Boolean, default: false },
    theme: { type: String, default: 'white' },
  },
  data() {
    return {
      t0: '',
      focusOn: false,
    };
  },
  watch: {
    value: 'update',
  },
  computed: {
    convertPlaceholder() {
      return this.placeholder.replace(/\n/gi, '<br />');
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.t0 = this.value;
      this.resize({ target: this.$refs.textBox });
    },
    change(e) {
      const { value } = e.target;
      this.t0 = (() => {
        switch (true) {
          case +this.maxLength > 0:
            return value.slice(0, +this.maxLength);
          case this.digit:
            return value.replace(/[^\d]/gi, '');
          default:
            return value;
        }
      })();
      this.$emit('input', this.t0);
    },
    blur() {
      this.focusOn = false;
      this.$emit('blur', this.t0);
    },
    focus() {
      this.focusOn = true;
      this.$emit('focus', this.t0);
    },
    resize({ target }) {
      this.$nextTick(() => {
        if (!this.autoHeight) return;
        target.style.height = '1px';
        const height = target.scrollHeight;
        const cssStyle = getComputedStyle(target);
        const maxHeight = Number(cssStyle.maxHeight.replace('px', ''));
        target.style.height = `${height}px`;
        target.scrollTop = maxHeight + height;
      });
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[text-box] { .rel; .block; .-box; .c(@c-placeholder); .br(12); .medium; .crop; .rel;
  &:after {.cnt; .abs; .wh(100%, 1); .bgc(rgba(0, 0, 0, 0.1)); .t-s(0); .lb(0, 0); transition: transform .3s;}
  &.focus:after {.bgc(rgba(0, 0, 0, 0.9)); .t-s(1);}
  .field {.fs(16, 20px); .wh(100%); .min-h(72); .-a; .bgc(@c-base-gray); .p(12); .-box; .c(@c-title-black); .block;
    &::placeholder {.c(@c-placeholder); .medium;}
  }
  &.dark { .br(6);
    &.focus:after {.bgc(#FFF);}
    .field { .fs(14, 20); .bgc(rgba(255, 255, 255, 0.04)); .-b(rgba(255, 255, 255, 0.1)); .c(#FFF);
      &::placeholder { .fs(13); }
    }
  }

  &.readonly{
      &:after{ .t-s(0); }
  }

  .placeholder{ .hide; .abs; .lt(0); .z(1); .wf; .p(inherit); .fs(inherit, inherit); .c(inherit); pointer-events: none; }

}
.error [text-box], [text-box].error {
  &:after { .bgc(#F90); .t-s(1);}
}
</style>
