<template>
  <div vote-progress>
    <VoteModify v-if="voteModify" :info="info" @close="voteModify = false" />
    <VoteList @modify="showModify" :info="info" />
  </div>
</template>

<script>
import VoteList from '@shared/components/vote/VoteList.vue';
import VoteModify from '@shared/components/vote/VoteModify.vue';

export default {
  VoteProgress: 'VoteProgress',
  components: { VoteModify, VoteList },
  props: {
    info: { type: Object, default: null },
    base: { type: Object, default: null },
  },
  data() {
    return {
      voteModify: false,
    };
  },
  computed: {
    openType() {
      return this.info.server.option.voteOpenType;
    },
    seeMore() {
      if (!this.info.server.answers) return false;
      return this.info.server.answers.length >= 5;
    },
  },
  methods: {
    showModify() {
      this.voteModify = !this.voteModify;
    },
  },
  mounted() {
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[vote-progress] { .w(100%); .bgc(white); .br(20); .-a(@c-base-gray, 1px); .rel; .pb(20);
  &.feed { .pb(5); .mt(20);}
  @media (@tp-up) {
    .w(100%);
    &.feed { .pb(8); .mt(20);
    }
  }
}
</style>
